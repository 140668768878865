.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-commerce-commercelayoutmain {
  -webkit-flex-basis: 800px;
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 8px;
  height: 38px;
  min-height: 30px;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
  height: 38px;
}

.w-commerce-commercequickcheckoutgoogleicon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercequickcheckoutmicrosofticon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutemailinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left-color: #e6e6e6;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #e6e6e6;
  border-right-width: 1px;
  border-right-style: solid;
}

.w-commerce-commercecheckoutshippingmethoditem {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  margin-left: 12px;
  margin-right: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardnumber::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardexpirationdate::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardsecuritycode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  font-weight: 400;
  margin-left: 8px;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  margin-left: 16px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: pre-wrap;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  -webkit-flex-basis: 320px;
  -ms-flex-preferred-size: 320px;
  flex-basis: 320px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  text-align: center;
}

.w-commerce-commercecheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commerceaddtocartform {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.w-commerce-commerceaddtocartoptionpill {
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #000;
  border-bottom-color: #000;
  border-left-color: #000;
  border-right-color: #000;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  color: #000;
  background-color: #fff;
  cursor: pointer;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartquantityinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: inline-block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 10px;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
}

.w-commerce-commerceaddtocarterror {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffdede;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercepaypalcheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    margin-right: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .w-commerce-commercelayoutsidebar {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardnumber {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardexpirationdate {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardsecuritycode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  font-family: Cabinetgrotesk, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 1.35;
  font-weight: 500;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #000;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #000;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

p {
  margin-bottom: 10px;
  color: #1c1c1c;
  font-size: 20px;
}

li {
  padding-top: 5px;
  padding-bottom: 5px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.section {
  position: relative;
}

.section.bg-yellow {
  background-color: #e2bceb;
}

.section.bg-yellow.divider-top {
  border-top: 3px solid #000;
}

.section.overflow {
  overflow: hidden;
}

.section.bg-light-blue.overflow-wrapper.d-none {
  display: block;
}

.section.bg-wheat {
  background-color: #e2bceb;
}

.container {
  position: relative;
  max-width: 1320px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.container.padding-top-large.title-layout-2 {
  padding-top: 160px;
}

.container.bg-sign-up {
  overflow: hidden;
  border-radius: 10px;
  background-color: #201f1f;
}

.inner-padding-large {
  padding-top: 22vh;
  padding-bottom: 22vh;
}

.inner-padding-medium {
  padding-top: 12vh;
  padding-bottom: 12vh;
}

.inner-padding-small {
  padding-top: 6vh;
  padding-bottom: 6vh;
}

.inner-padding-xsmall {
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.padding-bottom-large {
  padding-bottom: 22vh;
}

.padding-bottom-medium {
  padding-bottom: 12vh;
}

.padding-bottom-small {
  padding-bottom: 6vh;
}

.margin-bottom-large {
  margin-bottom: 20vh;
}

.margin-bottom-medium {
  margin-bottom: 12vh;
}

.margin-bottom-small {
  margin-bottom: 6vh;
}

.margin-bottom-xsmall {
  margin-bottom: 3vh;
}

.center-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.center-wrapper.inner-column {
  height: 100vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.center-wrapper.inner-column.video-wrapper {
  padding-top: 12vh;
  padding-right: 3vw;
  padding-left: 3vw;
}

.center-wrapper.inner-column.second {
  padding-right: 3vw;
  padding-left: 3vw;
}

.center-wrapper.hero.overflow {
  overflow: hidden;
}

.center-wrapper.box-column {
  height: 100vh;
  padding: 5vh;
}

.center-wrapper.right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.content-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.content-wrapper.right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.content-wrapper.rotate-15 {
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.content-wrapper.rotate--10 {
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.content-wrapper.form {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content-wrapper.outline {
  overflow: hidden;
  border: 3px solid #000;
  border-radius: 10px;
}

.content-wrapper.detail {
  height: 15vh;
  padding-bottom: 35px;
}

.content-wrapper.box {
  padding-bottom: 35px;
}

.content-wrapper.bg-yellow.rounded-box {
  overflow: hidden;
  border: 5px solid #000;
  border-radius: 25px;
}

.content-wrapper.list {
  display: none;
}

.overflow-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.main-grid {
  position: relative;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #e2bceb;
}

.main-grid.full-page {
  z-index: 5;
}

.main-grid.full-page.overflow {
  overflow: hidden;
}

.main-grid.center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr;
  text-align: center;
  text-decoration: none;
}

.main-grid.padding-bottom-large {
  background-color: #e2bceb;
}

.main-grid.flex {
  width: 100%;
  padding-top: 15vh;
  padding-bottom: 15vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.main-grid.flex.gallery {
  padding-left: 3vw;
}

.main-grid.hero {
  min-height: 100vh;
}

.main-grid.overflow {
  overflow: hidden;
}

.main-grid.no-gap {
  position: relative;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.main-grid.no-gap.top {
  z-index: 22;
}

.main-grid.full-list {
  z-index: 5;
  min-height: 100%;
}

.main-grid.stretch-hero {
  min-height: 100vh;
  -ms-grid-rows: 15vh auto 15vh;
  grid-template-rows: 15vh auto 15vh;
}

.main-grid.full-height {
  overflow: hidden;
  height: 100vh;
}

.main-grid.inner-padding-small {
  background-color: #e2bceb;
}

.main-grid.top-hero {
  max-height: 90vh;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
}

.main-grid.large-gap {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
}

.simple-grid {
  position: relative;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.simple-grid.stretch {
  height: 100%;
}

.simple-grid.stretch.no-gap {
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-auto-flow: column;
  grid-column-gap: 0px;
}

.main-paragraph {
  color: #1c1c1c;
}

.main-paragraph.text-medium {
  position: relative;
  z-index: 4;
  color: #000;
}

.main-paragraph.text-medium.opacity {
  opacity: 0.5;
}

.main-paragraph.text-medium.margin-bottom-xsmall {
  font-family: 'Changa One', Impact, sans-serif;
  text-align: left;
}

.main-paragraph.text-small.bold {
  font-weight: 700;
}

.text-small {
  font-size: 16px;
}

.text-medium {
  color: #000;
  font-size: 2em;
  line-height: 1.35;
}

.heading-large {
  position: relative;
  z-index: 2;
  margin-top: 5vh;
  margin-bottom: 60px;
  font-family: 'Permanent Marker', sans-serif;
  color: #000;
  font-size: 60px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
}

.heading-large.no-wrap {
  white-space: nowrap;
}

.heading-large.hero {
  font-size: 16vh;
}

.heading-medium {
  position: relative;
  z-index: 2;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 7.5vh;
  line-height: 0.95;
  font-weight: 400;
}

.heading-medium.margin-bottom-xsmall {
  margin-bottom: 20px;
  font-family: 'Permanent Marker', sans-serif;
}

.heading-medium.no-wrap {
  white-space: nowrap;
}

.heading-medium.regular-type {
  font-family: Cabinetgrotesk, sans-serif;
}

.heading-medium.margin-bottom-small {
  margin-bottom: 45px;
}

.heading-regular {
  position: relative;
  z-index: 2;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 3vh;
  line-height: 1.1;
  font-weight: 400;
}

.heading-regular.regular-type {
  font-family: Cabinetgrotesk, sans-serif;
  font-size: 25px;
}

.heading-regular.margin {
  margin-bottom: 15px;
}

.heading-small {
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 20px;
  line-height: 1.2;
}

.heading-small.regular-type {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.cover-image {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0px 0px;
  background-size: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.cover-image.outline {
  box-shadow: 5px -5px 0 0 #000, 5px 5px 0 0 #000, -5px 5px 0 0 #000, -5px -4px 0 0 #000;
}

.contain-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.button {
  padding: 20px 30px;
  border-radius: 100px;
  background-color: #e0e0e0;
  font-size: 20px;
  font-weight: 700;
}

.heading-xlarge {
  position: relative;
  z-index: 2;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 28vh;
  line-height: 0.8;
  font-weight: 400;
}

.heading-xlarge.no-wrap {
  letter-spacing: -0.01em;
  white-space: nowrap;
}

.heading-xlarge.split {
  font-size: 24vh;
}

.heading-xlarge.hover {
  display: block;
}

.heading-xlarge.hero {
  font-size: 33vh;
  white-space: nowrap;
}

.full-page {
  min-height: 100vh;
}

.full-page.center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
}

.sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.sticky-wrapper.sidebar {
  height: auto;
}

.sticky-wrapper.background {
  z-index: 12;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sticky-wrapper.home-page {
  top: 120px;
}

.navbar-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 90;
  width: 100%;
  height: 10vh;
}

.navbar {
  height: 10vh;
  background-color: transparent;
}

.nav-grid {
  height: 100%;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brand {
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vh;
  height: 6vh;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-move {
  position: relative;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: block;
  width: 25vw;
  height: 55vh;
  max-height: 580px;
  margin-right: auto;
  margin-left: auto;
  -webkit-perspective-origin: 50% 100%;
  perspective-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.inner-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 3px solid #000;
  border-radius: 20px;
  background-color: #f7a6a6;
  box-shadow: 7px 7px 0 0 #000;
  -webkit-perspective-origin: 50% 100%;
  perspective-origin: 50% 100%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.inner-image.back {
  z-index: 1;
}

.inner-image.middle {
  z-index: 2;
  background-color: #f7daa6;
}

.inner-image.front {
  z-index: 3;
  background-color: #f67575;
}

.nav-menu {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  min-height: 100vh;
  padding-top: 12vh;
  background-color: #99cbd7;
}

.main-button {
  padding: 20px 40px;
  border: 3px solid #000;
  border-radius: 100px;
  background-color: #f8e7c2;
  box-shadow: 6px 6px 0 0 #000;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.main-button:hover {
  box-shadow: 1px 1px 0 0 #000;
}

.main-button.small {
  padding: 12px 25px;
  color: #000;
  font-size: 18px;
}

.main-button.edit-btn {
  margin-top: 40px;
  background-color: transparent;
}

.menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: auto;
  margin-right: 1vw;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #000;
  border-radius: 100px;
  box-shadow: 6px 6px 0 0 #000;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-family: Tanker, sans-serif;
  color: #000;
  text-align: center;
}

.menu-button:hover {
  box-shadow: 1px 1px 0 0 #000;
}

.menu-button.w--open {
  background-color: transparent;
  color: #1c1c1c;
}

.bg-yellow {
  background-color: #ffca0e;
}

.bg-light-salmon {
  background-color: #fe9d73;
}

.text-white {
  color: #fff;
}

.bg-light-coral {
  background-color: #ee3f54;
  background-image: none;
  background-size: auto;
}

.bg-yellow-green {
  background-color: #85eca9;
}

.bg-light-blue {
  background-color: #99cbd7;
}

.bg-thistle {
  background-color: #e2bceb;
}

.heading-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.heading-wrapper.heading-center {
  max-width: 700px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-logo {
  height: 35px;
}

.link-hero {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: -19%;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 15vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-hero.page {
  position: relative;
  bottom: 0%;
}

.text-menu {
  position: relative;
  font-size: 20px;
}

.text-menu.close {
  display: none;
}

.text-menu.on {
  background-image: url('images/twitter-black.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  text-decoration: none;
}

.cta-label {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 15vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  transform: rotate(-25deg);
  font-family: Tanker, sans-serif;
  font-size: 22px;
}

.cta-label.text-yellow {
  font-family: Cabinetgrotesk, sans-serif;
  color: #ffe837;
}

.cta-label.text-dark {
  font-family: Cabinetgrotesk, sans-serif;
  color: #000;
}

.shape-button {
  width: 100%;
}

.shape-button.small {
  height: 6vh;
  margin-right: 1vw;
  margin-left: 1vw;
}

.shape-button.large {
  position: relative;
  z-index: -1;
  height: 45vh;
}

.shape-button.cover {
  height: 100%;
}

.shape-button.center {
  position: relative;
  left: 0%;
  right: 0%;
  bottom: auto;
  max-height: 65vh;
  margin-right: auto;
  margin-left: auto;
}

.shape-button.medium {
  width: 4vw;
  margin-right: 20px;
  margin-left: 20px;
  border: 3px solid #000;
  border-radius: 100px;
}

.shape-button.micro {
  width: 18px;
  height: 18px;
  margin-right: 9px;
  margin-left: 9px;
  border: 1px solid #000;
  border-radius: 100px;
}

.shape-button.detail {
  position: absolute;
  left: -5vw;
  top: -5vh;
  width: 15vw;
}

.shape-button.head {
  width: 80px;
}

.shape-button.card {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 0;
}

.shape-button.card.med {
  top: 4%;
  width: 30vh;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.hero-section-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.inner-ticker-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ticker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ticker.outline {
  border-top: 4px solid #000;
  border-bottom: 3px solid #000;
}

.ticker.outline.top {
  border-bottom-style: none;
}

.grid {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid.top-nav {
  display: none;
}

.image-link.social-icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 8px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-page-wrapper {
  position: relative;
  padding-top: 16vh;
  padding-bottom: 10vh;
}

.regular-link {
  font-size: 18px;
  text-decoration: none;
}

.regular-link.text-gray:hover {
  color: #c7c7c7;
}

.nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 8em;
  line-height: 0.6;
  text-align: left;
}

.nav-link:hover {
  background-image: url('images/scribble-line-02.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-link.one {
  display: inline-block;
  color: #000;
}

.nav-link.two {
  display: inline-block;
  color: #000;
}

.nav-link.three {
  color: #000;
}

.nav-link.four {
  color: #000;
}

.bg-blue-gray {
  background-color: #e0f5fa;
}

.figure-wrapper {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -110px;
  width: 220px;
  height: 220px;
  margin-right: auto;
  margin-left: auto;
}

.figure-wrapper.left {
  left: -10%;
  top: 15%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10vw;
  height: 10vw;
}

.figure-wrapper.right {
  left: auto;
  top: 14%;
  right: -19%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10vw;
  height: 10vw;
}

.figure-wrapper.top {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.figure-wrapper.page {
  position: relative;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 180px;
  margin-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.figure-wrapper.center {
  position: relative;
  bottom: 0px;
  width: 150px;
  height: 150px;
}

.figure-wrapper.ticker {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.collection-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto 1fr 1fr;
  grid-template-columns: auto auto 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.link-inline-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.divider-bullet {
  position: relative;
  z-index: 6;
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 1vw;
  margin-left: 1vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 20px;
  background-color: #000;
}

.container-wrapper {
  width: 100%;
  max-width: 1380px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #99cbd7;
}

.container-wrapper.inner-page {
  padding-top: 15vh;
}

.container-wrapper.header-page {
  padding-top: 10vh;
}

.container-wrapper.header-page.home {
  padding-top: 1vh;
  padding-bottom: 15vh;
  background-color: #e2bceb;
}

.container-wrapper.full-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-wrapper.inner-page-section {
  padding-top: 5vh;
}

.container-wrapper.default-container {
  max-width: 1380px;
  background-color: transparent;
}

.image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.image-wrapper.outline {
  z-index: 4;
  width: 100%;
  margin-bottom: 20px;
  border: 5px solid #000;
  border-radius: 15px;
  box-shadow: none;
}

.image-wrapper.outline.rotate-10 {
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}

.image-wrapper.outline.rotate--5 {
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.image-wrapper.outline.cc-circle {
  width: 33vw;
  height: 33vw;
  border-radius: 100%;
}

.image-wrapper.outline.mid-size {
  max-height: 30vh;
}

.image-wrapper.outline.feed {
  height: 100%;
  max-width: 500px;
  border-color: #e2bceb;
  border-radius: 40px;
}

.image-wrapper.shadow {
  width: 100%;
  margin-bottom: 20px;
  border: 4px solid #000;
  border-radius: 20px;
  box-shadow: 32px 32px 0 0 #000;
}

.image-wrapper.rounded-outline {
  width: 100%;
  height: 100%;
  border: 5px solid #000;
  border-radius: 18px;
}

.image-wrapper.header-post {
  height: 70vh;
  border-right: 3px solid #000;
}

.image-wrapper.post {
  height: 40vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 3px solid #000;
}

.image-wrapper.food-post {
  position: relative;
  top: 0px;
  height: 100%;
  max-height: none;
  border-right: 4px solid #000;
}

.image-wrapper.circle {
  width: 15vw;
  height: 15vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 4px solid #000;
  border-radius: 200px;
}

.image-wrapper.circle.shadow {
  box-shadow: 13px 16px 0 0 #000;
}

.image-wrapper.section-outline {
  z-index: 10;
  height: 80vh;
  border: 5px solid #000;
  border-radius: 20px;
  -webkit-perspective-origin: 50% 100%;
  perspective-origin: 50% 100%;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.image-wrapper.outlined {
  width: 90vw;
  height: 80vh;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  border: 6px solid #000;
  border-radius: 19px;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.text-large {
  color: #000;
  font-size: 5.25em;
  line-height: 1;
  font-weight: 700;
}

.padding-top-large {
  padding-top: 22vh;
}

.padding-top-medium {
  padding-top: 12vh;
}

.tabs-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 70vh;
  padding-top: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tabs {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.75fr 2fr;
  grid-template-columns: 0.75fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tab {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 6px 25px 4px;
  border: 3px solid #000;
  border-radius: 100px;
  background-color: transparent;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 3.4vh;
  font-weight: 500;
}

.tab.w--current {
  background-color: #000;
  color: #e2bceb;
}

.combo-img-wrapper {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 5px solid #000;
  border-radius: 20px;
  box-shadow: none;
  text-align: left;
}

.combo-img-wrapper._2 {
  position: relative;
  -webkit-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
}

.combo-img-wrapper._1 {
  max-height: 400px;
  -webkit-transform: rotate(31deg);
  -ms-transform: rotate(31deg);
  transform: rotate(31deg);
}

.combo-img-wrapper.hero {
  z-index: 6;
  max-height: 45vh;
  max-width: 33vw;
}

.combo-img-wrapper.mid-size {
  max-height: 45vh;
}

.combo-img-wrapper.square {
  height: 30vh;
}

.collection-item {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-wrapper {
  position: relative;
  z-index: 4;
  overflow: hidden;
  width: 78vw;
  height: 75vh;
  border-style: solid;
  border-width: 6px;
  border-color: #1d1d1d;
  border-radius: 20px;
  background-color: #1d1d1d;
  text-decoration: none;
}

.bg-black {
  background-color: #1c1c1c;
}

.text-yellow {
  color: #ffca0e;
}

.flex-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  margin-right: 1vh;
  margin-left: 1vh;
  padding: 40px 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 0 0 #000;
  -webkit-transition: box-shadow 200ms ease, border 200ms ease;
  transition: box-shadow 200ms ease, border 200ms ease;
  text-decoration: none;
}

.flex-card:hover {
  border-style: solid;
  border-color: #000;
  box-shadow: 7px 7px 0 0 #000;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.flex-card.content-four:hover {
  background-color: #ffe837;
}

.half-grid {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.half-grid.bottom-line {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
}

.thumb-card {
  overflow: hidden;
  height: 340px;
  border: 3px solid #000;
  border-radius: 9px;
}

.thumb-card.shadow {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 7px 7px 0 0 #000;
}

.thumb-card.shadow.medium {
  height: 450px;
}

.card-grid-wrapper {
  position: relative;
  width: 100%;
  padding: 32px 35px 55px;
  border-style: solid;
  border-width: 4px;
  border-color: transparent;
  border-radius: 22px;
  text-decoration: none;
}

.card-grid-wrapper:hover {
  border: 4px solid #000;
  background-color: #d9aae4;
}

.card-grid-wrapper.feed {
  padding-bottom: 10px;
}

.circle-wrapper {
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 17vw;
  height: 17vw;
  max-height: 220px;
  max-width: 220px;
  margin-bottom: 20px;
  border: 7px solid #000;
  border-radius: 200px;
}

.design-detail {
  position: absolute;
  left: auto;
  top: -20%;
  right: 0%;
  bottom: auto;
}

.span-image-first {
  position: relative;
  bottom: -2px;
  display: inline-block;
  width: 18vw;
  height: 0.8em;
  margin-right: 9px;
  margin-left: 9px;
  border: 5px solid #000;
  border-radius: 10px;
  background-image: url('images/close-up-woman-holding-taco-with-blue-background.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.scribble {
  background-image: url('images/scribble-line-01.svg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-wheat {
  background-color: #f8e7c2;
}

.title-tag {
  position: relative;
  bottom: -1.4vh;
  z-index: 5;
  padding: 8px 11px;
  border-radius: 4px;
  background-color: #ff5a6e;
  -webkit-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
  font-family: Tanker, sans-serif;
  color: #ffd88e;
  font-size: 22px;
  font-weight: 400;
}

.title-tag.bg-light-blue {
  background-color: #39bfdf;
  color: #ffe200;
  white-space: nowrap;
}

.title-tag.page {
  left: -1.6vw;
  top: -1.4vh;
  bottom: 0vh;
  z-index: 12;
  padding: 15px 32px;
}

.big-type-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-frame-gallery {
  overflow: hidden;
  height: 42vh;
  border: 6px solid #000;
  border-radius: 12px;
}

.image-frame-gallery.first {
  -webkit-transform: rotate(12deg);
  -ms-transform: rotate(12deg);
  transform: rotate(12deg);
}

.image-frame-gallery.second {
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.image-frame-gallery.third {
  height: 55vh;
  -webkit-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
}

.image-frame-gallery.rotate {
  position: relative;
  z-index: 2;
  height: 66vh;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.image-frame-gallery.square {
  height: 35vh;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.image-frame-gallery.square.reverse {
  -webkit-transform: rotate(7deg);
  -ms-transform: rotate(7deg);
  transform: rotate(7deg);
}

.image-frame-gallery.back {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 35vw;
  height: 60vh;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
}

.heading-xlarge-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bg-red {
  background-color: #e92500;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.span-image-two {
  position: relative;
  bottom: -2px;
  display: inline-block;
  width: 18vw;
  height: 0.8em;
  margin-right: 9px;
  margin-left: 9px;
  border: 5px solid #000;
  border-radius: 10px;
  background-image: url('images/chicken-burger-with-tomato-lettuce-leaf-chicken-patty-burger-buns-2.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.link-block-heading {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 12.5vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-decoration: none;
}

.hover-image-wrapper {
  width: 18vw;
  height: 22.5vh;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 3px solid #000;
  border-radius: 6px;
  background-color: #5c5c5c;
}

.section-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.section-background.full-hero {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-background.fixed {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.frame-image-one {
  overflow: hidden;
  min-height: auto;
  border: 4px solid #000;
  border-radius: 8px;
}

.frame-image-two {
  overflow: hidden;
  min-height: auto;
  border: 4px solid #000;
  border-radius: 8px;
}

.frame-image-three {
  overflow: hidden;
  min-height: auto;
  border: 4px solid #000;
  border-radius: 8px;
}

.hero-rotate-image {
  position: relative;
  z-index: 5;
  overflow: hidden;
  width: 40vw;
  height: 65vh;
  max-width: 680px;
  margin-right: auto;
  margin-left: auto;
  border: 6px solid #000;
  border-radius: 12px;
  -webkit-perspective-origin: 0% 0%;
  perspective-origin: 0% 0%;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.sd1 {
  position: absolute;
  z-index: 6;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.sd2 {
  position: absolute;
  z-index: 7;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.sd3 {
  position: absolute;
  z-index: 8;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sd4 {
  position: absolute;
  z-index: 9;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.sd5 {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.sd6 {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.back-shadow {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  height: 100%;
  max-height: 58vh;
  max-width: 30vw;
  border-radius: 12px;
  background-color: #000;
  -webkit-transform: translate(-76px, 48px) rotate(-23deg);
  -ms-transform: translate(-76px, 48px) rotate(-23deg);
  transform: translate(-76px, 48px) rotate(-23deg);
}

.collection-list-section {
  position: relative;
}

.flex-ticker {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.flex-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-detail {
  height: 35px;
}

.image-detail.margin-micro {
  margin-bottom: 15px;
}

.image-detail.medium {
  height: 90px;
  margin-bottom: 25px;
}

.hero-image-container {
  position: relative;
  z-index: 6;
  overflow: visible;
  max-width: 34vw;
  -webkit-perspective-origin: 50% 100%;
  perspective-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.track {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 550vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.frame-image {
  overflow: hidden;
  width: 65vw;
  height: 75vh;
  max-width: 980px;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid #000;
  border-radius: 10px;
  background-color: #e97f7f;
  -webkit-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
}

.frame-image.sec-three {
  width: 45vw;
  height: 67vh;
  margin-right: 0px;
  -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg);
}

.frame-image.sec-four {
  width: auto;
  height: 55vh;
  margin-right: 0px;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.detail-hero-grid {
  position: relative;
  z-index: 12;
  display: -ms-grid;
  display: grid;
  padding: 20px 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.center-image-wrapper {
  position: relative;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-radius: 15px;
}

.center-image-wrapper-mid {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-radius: 15px;
}

.center-image-wrapper-back {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-radius: 15px;
}

.z-index {
  position: relative;
  z-index: 10;
}

.wrapper-footer {
  position: relative;
  z-index: 10;
}

.specials-track {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.image-wrapper-col {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #e55f5f;
  text-align: left;
}

.specials-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 4px solid #000;
}

.rail-hero-skew {
  position: absolute;
  left: 103.1vh;
  top: -30vh;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 21vw;
  height: 130vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg);
}

.span-heading {
  position: relative;
  left: 15vw;
}

.hero-inner-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 60vh;
  padding-top: 15vh;
  padding-bottom: 15vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.padding-hero {
  display: block;
  padding-top: 72px;
  padding-bottom: 72px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.text-span {
  position: relative;
  left: 24.4vw;
}

.text-span-two {
  position: relative;
  left: -8.2vw;
  background-image: url('images/scribble-line-01.svg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-span-three {
  position: relative;
  left: 23.9vw;
}

.text-span-four {
  position: relative;
  left: 0.2vw;
}

.heading-wrapper-flex {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-left: 3vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.skew-image-wrapper {
  overflow: hidden;
  height: 60vh;
  margin-bottom: 4vh;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 5px solid #000;
  border-radius: 20px;
  background-color: #383838;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  -webkit-transform: perspective(2000px);
  transform: perspective(2000px);
}

.vertical-ticker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.design-detail-arrow {
  position: absolute;
  left: auto;
  top: auto;
  right: 12%;
  bottom: 0%;
  -webkit-transform: rotate(66deg);
  -ms-transform: rotate(66deg);
  transform: rotate(66deg);
}

.design-detail-arrow.whitepaper-arrow {
  left: auto;
  right: auto;
  bottom: 24%;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.heading-large-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 22vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.link-content-section {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding-right: 35px;
  padding-left: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  text-decoration: none;
}

.link-block-wrapper {
  text-decoration: none;
}

.hover-ticker-card {
  position: absolute;
  left: 0%;
  top: 12.5vh;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #000;
  font-family: Tanker, sans-serif;
  font-size: 33px;
}

.text-block {
  margin-right: 10px;
  margin-left: 10px;
  color: #ffd88e;
  font-size: 20px;
}

.small-number {
  position: relative;
  z-index: 12;
  margin-right: 15px;
  padding-top: 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.link-content-heading {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 22.5vh;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-decoration: none;
}

.spacer-circle {
  width: 10px;
  height: 10px;
  margin-right: 9px;
  margin-left: 9px;
  border-radius: 20px;
  background-color: #000;
}

.heading-page-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 40vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #000;
  border-radius: 6px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
  text-align: center;
}

.divider {
  width: 100%;
  height: 5vh;
  background-image: url('images/divider.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.divider.version-01 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -0.5%;
  z-index: 10;
  background-image: url('images/divider-2.svg');
  background-position: 50% 100%;
  background-size: 100%;
}

.divider.margin-bottom-medium {
  margin-bottom: 2vh;
}

.divider.version-02 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -0.5%;
  z-index: 10;
  background-image: url('images/divider-2.svg');
  background-position: 50% 100%;
  background-size: 100%;
}

.divider.version-03 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  background-image: url('images/divider-yellow.svg');
  background-position: 50% 100%;
  background-size: 100%;
}

.divider.version-02 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -0.5%;
  z-index: 10;
  background-image: url('images/pink-svg.svg');
  background-position: 50% 100%;
  background-size: 100%;
}

.divider.container-divider {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -0.5%;
  z-index: 10;
  background-image: url('images/divider.svg');
  background-position: 50% 100%;
  background-size: 100%;
}

.opacity {
  color: rgba(51, 51, 51, 0.75);
}

.link-cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 3px solid #000;
  border-radius: 12px;
  box-shadow: 0 0 0 0 #000;
  -webkit-transition: box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 600ms cubic-bezier(.368, -.093, .32, 1.275);
  transition: box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 600ms cubic-bezier(.368, -.093, .32, 1.275);
  transition: transform 600ms cubic-bezier(.368, -.093, .32, 1.275), box-shadow 200ms ease, background-color 200ms ease;
  transition: transform 600ms cubic-bezier(.368, -.093, .32, 1.275), box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 600ms cubic-bezier(.368, -.093, .32, 1.275);
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.link-cards:hover {
  background-color: #fff;
  box-shadow: 3px 4px 0 0 #000;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

.screenshot-wrapper {
  overflow: hidden;
  width: 100%;
  height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px 1px 3px;
  border-color: #000;
}

.screenshot-wrapper.square {
  height: 10vw;
}

.screenshot-wrapper.image {
  border-style: none none solid;
}

.alt-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-heading-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: none;
  border-width: 1px;
  border-color: #ff1212;
}

.footer-divider {
  position: relative;
  bottom: -1px;
  width: 100%;
  height: 6vh;
  background-image: url('images/divider-black.svg');
  background-position: 50% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.inner-section {
  overflow: hidden;
}

.footer-wrapper {
  width: 100%;
  background-color: #e2bceb;
}

.png-grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  min-height: auto;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-align-content: end;
  -ms-flex-line-pack: end;
  align-content: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.cart-button {
  width: 38px;
  height: 38px;
  margin-right: 10px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 3px solid #000;
  border-radius: 20px;
  background-color: #b6d81f;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.cart-quantity {
  height: auto;
  min-width: auto;
  margin-left: 0px;
  padding-left: 4px;
  background-color: #b6d81f;
  font-family: Cabinetgrotesk, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 1.2;
}

.buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field {
  height: 60px;
  border: 3px solid #000;
  border-radius: 8px;
  font-size: 18px;
}

.form-block {
  width: 100%;
}

.field-label {
  color: #000;
  font-size: 18px;
  text-align: left;
}

.auto-grid {
  grid-auto-flow: column;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.header-page {
  padding-top: 12vh;
}

.text-blue {
  color: #1c1fca;
}

.ticker-heading-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 16vw;
  height: 5vh;
  border: 2px solid #000;
  border-radius: 4px;
  background-color: #99cbd7;
  -webkit-transform: translate(-2vw, 2vh) rotate(-10deg);
  -ms-transform: translate(-2vw, 2vh) rotate(-10deg);
  transform: translate(-2vw, 2vh) rotate(-10deg);
}

.text-gray {
  color: #858585;
}

.column-divider {
  width: 3px;
  height: 100%;
  border-radius: 100px;
  background-color: #000;
}

.column {
  padding-right: 5vw;
  padding-left: 5vw;
  border-right-width: 3px;
  border-right-color: #000;
}

.column.full-height {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-left: 0vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column.full-height.inner-padding-medium {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.column.form {
  padding-top: 80px;
  padding-right: 45px;
  padding-left: 45px;
}

.collection-list-wrapper {
  width: 100%;
}

.link-content-wrapper {
  display: inline;
  width: 100%;
  color: #000;
  text-decoration: none;
}

.link-content-wrapper.outline {
  display: inline-block;
  overflow: hidden;
  height: 100%;
  border: 3px solid #000;
  border-radius: 10px;
}

.link-content-wrapper.styleguide {
  display: block;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.heading-xsmall {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
}

.card-grid {
  height: 100%;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-column-gap: 0px;
  grid-row-gap: 25px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.card-grid.padding-bottom-small {
  position: relative;
  z-index: 3;
}

.flex-card-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 34px;
  padding-bottom: 30px;
  padding-left: 34px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.form-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.text-field-sub {
  height: 60px;
  margin-bottom: 0px;
  padding-left: 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background-color: #201f1f;
  font-size: 18px;
}

.text-field-sub::-webkit-input-placeholder {
  color: #fff;
}

.text-field-sub:-ms-input-placeholder {
  color: #fff;
}

.text-field-sub::-ms-input-placeholder {
  color: #fff;
}

.text-field-sub::placeholder {
  color: #fff;
}

.submit-button {
  height: 60px;
  padding-right: 30px;
  padding-left: 25px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.span-copyright {
  position: relative;
  top: -1.4em;
  font-family: Cabinetgrotesk, sans-serif;
  font-size: 12vh;
  line-height: 0.5;
}

.span-copyright.medium {
  top: -5.8vh;
  font-size: 7vh;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-content.password {
  width: 450px;
  padding-right: 25px;
  padding-left: 25px;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.form-block-subscribe {
  margin-bottom: 0px;
}

.bg-white {
  background-color: #fff;
}

.box-center-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.box-center-wrapper.left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.box-checkout {
  overflow: hidden;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
}

.box-checkout.pop-up {
  padding: 10px;
  border-style: none;
  border-radius: 40px;
}

.checkout-form {
  padding-top: 0px;
  padding-bottom: 0px;
}

.remove-button {
  color: #9e9e9e;
  text-decoration: none;
}

.bg-slate-blue {
  background-color: #8c4dff;
}

.collection-list-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-list-grid._4-col {
  grid-column-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.collection-list-grid._2-col {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.collection-list-grid-wrapper {
  width: 100%;
}

.figure-wrapper-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30vh;
  height: 22vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.figure-wrapper-heading.absolute {
  position: absolute;
  z-index: 6;
}

.paragraph-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.paragraph-wrapper.col {
  padding-top: 80px;
  padding-bottom: 80px;
}

.small-link {
  color: #fff;
  text-decoration: none;
}

.small-link.jp {
  display: inline-block;
  padding: 7px 13px;
  border-radius: 6px;
  background-color: #3c3b3b;
}

.image-social {
  height: 100%;
}

.success {
  background-color: #b6d81f;
  font-size: 16px;
}

.error {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fe9d73;
  font-size: 16px;
  text-align: center;
}

.textarea {
  min-height: 250px;
  border: 3px solid #000;
  border-radius: 8px;
  font-size: 18px;
}

.divider-burger {
  margin-top: 15px;
  margin-bottom: 15px;
}

.section.full-page-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.color-swatch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  padding-right: 38px;
  padding-left: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.color-swatch.bg-black {
  background-color: #000;
  color: #fff;
}

.color-swatch.bg-wheat {
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
}

.span-emoji {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  background-image: url('images/hamburger_1f354_1hamburger_1f354.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.span-emoji.smile {
  background-image: url('images/smiling-face-with-smiling-eyes_1f60a_1smiling-face-with-smiling-eyes_1f60a.png');
}

.span-emoji.boring {
  background-image: url('images/sleeping-face_1f634_1sleeping-face_1f634.png');
}

.form-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 43px 44px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 5px solid #000;
  border-radius: 15px;
}

.regular-link-block {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.regular-link-block.underline {
  padding-bottom: 10px;
  background-image: url('images/divider-thick.svg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transition: padding 200ms ease;
  transition: padding 200ms ease;
  text-decoration: none;
}

.regular-link-block.underline:hover {
  padding-left: 10px;
}

.nav-link-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.list-wrapper {
  width: 100%;
}

.collection-list-wrapper-2 {
  width: 100%;
}

.div-block {
  background-color: #e2bceb;
}

.container-2 {
  position: static;
  left: auto;
  right: auto;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0px 0px;
  background-size: auto;
}

.image-2 {
  border-radius: 20px;
}

.main-paragraph-2 {
  color: #1c1c1c;
}

.main-paragraph-2.margin-bottom-xsmall {
  font-family: 'Architects Daughter', sans-serif;
}

.main-paragraph-2.text-medium {
  position: relative;
  z-index: 4;
  color: #000;
}

.main-paragraph-2.text-medium.margin-bottom-small {
  font-family: 'Architects Daughter', sans-serif;
  font-size: 24px;
}

.container-wrapper-2 {
  width: 100%;
  max-width: 1380px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #99cbd7;
}

.container-wrapper-2.inner-page {
  padding-top: 0vh;
  background-color: #e2bceb;
}

.heading {
  font-family: Tanker, sans-serif;
  text-align: center;
}

.container-3 {
  text-align: center;
}

.container-4 {
  text-align: center;
}

.image-3 {
  border-radius: 20px;
}

.heading-2 {
  font-family: Tanker, sans-serif;
  font-size: 30px;
}

.heading-3 {
  font-family: Tanker, sans-serif;
}

.grid-3 {
  min-width: 300px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

.nav-grid-2 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: block;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.nav-logo {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link-2 {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  text-decoration: none;
}

.nav-link-2:hover {
  text-decoration: underline;
}

.div-block-2 {
  height: 100vh;
}

.navbar-wrapper {
  padding-right: 30px;
  padding-left: 30px;
}

.navbar-container {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.nav-logo {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Permanent Marker', sans-serif;
  font-weight: 400;
}

.nav-logo.footer-logo {
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 1.4;
}

.brand-2 {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

.div-block-3 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.nav-link-menu {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.social-link {
  margin-left: 60px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.social-link.footer-social-link {
  margin-top: 10px;
  margin-bottom: 20px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.social-link-image {
  width: 40px;
}

.social-link-block.last-child {
  margin-left: 20px;
}

.hero {
  position: relative;
  height: 100vh;
  min-height: 850px;
  padding-top: 100px;
  background-color: #e2bceb;
}

.hero-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-gif {
  width: 500px;
  border-radius: 30px;
}

.primary-heading-2 {
  margin-bottom: 40px;
  font-family: 'Permanent Marker', sans-serif;
  font-size: 76px;
  line-height: 1em;
  font-weight: 400;
  text-transform: uppercase;
}

.hero-subtext {
  font-family: 'Architects Daughter', sans-serif;
  font-size: 24px;
  line-height: 1.6em;
  font-weight: 500;
}

.hero-left {
  max-width: 700px;
}

.navbar-section {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: none;
  background-color: transparent;
}

.text-block-2 {
  font-family: Inconsolata, monospace;
}

.nft-image-collages.section-padding {
  position: relative;
}

.section-heading {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.section-title {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Permanent Marker', sans-serif;
  font-size: 60px;
  line-height: 1.3em;
  font-weight: 400;
}

.section-subtitle {
  margin-bottom: 0px;
  padding-bottom: 10px;
  font-family: 'Architects Daughter', sans-serif;
  font-size: 24px;
  line-height: 1.35em;
  text-align: center;
}

.splide__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.splide__slide {
  width: 33.333333333333336%;
  height: 40vw;
  min-height: 41.88em;
  padding-right: 0.52em;
  padding-left: 0.52em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.splide__img {
  width: 100%;
  height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.controls {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 13em;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1vw;
  line-height: 1.6;
}

.control.prev-splide {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 3.33em;
  height: 3.33em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  font-size: 1em;
  line-height: 1.6;
  font-weight: 700;
  text-decoration: none;
}

.control.prev-splide:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.control.next-splide {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 3.33em;
  height: 3.33em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  font-size: 1em;
  line-height: 1.6;
}

.control.next-splide:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.control__arrow {
  position: relative;
  z-index: 2;
  width: 1.5em;
  height: 1em;
  opacity: 1;
}

.control__arrow.is--flipped {
  height: 1.3em;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.control__fill {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin-top: -100%;
  margin-right: -100%;
  border-radius: 50%;
  background-color: #1c1c1c;
}

.overflow {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slider__wave {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  margin-top: -0.6vw;
}

.slider__wave.is--bottom {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-top: 0vw;
  margin-bottom: -0.6vw;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.nft-image-collage-sec1 {
  background-color: #f8e7c2;
}

.mycollage.is--slider {
  position: relative;
  background-color: #f8e7c2;
}

.cursor {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.cursor.hide-cursor {
  display: none;
}

.cursor__dot1 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1c1c1c;
  -webkit-transition: opacity 350ms ease, background-color 350ms ease, height 350ms ease, width 350ms ease;
  transition: opacity 350ms ease, background-color 350ms ease, height 350ms ease, width 350ms ease;
}

.cursor__dot1.is--larger {
  width: 8.23em;
  height: 8.23em;
  background-color: #e2bceb;
}

.cursor__dot1.opacity-0 {
  opacity: 0;
}

.cursor__dot2 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 52px;
  height: 52px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 50%;
  -webkit-transition: color 350ms ease, background-color 350ms ease, width 350ms ease, height 350ms ease, border-color 350ms ease;
  transition: color 350ms ease, background-color 350ms ease, width 350ms ease, height 350ms ease, border-color 350ms ease;
}

.cursor__dot2.is--larger {
  width: 8.23em;
  height: 8.23em;
  background-color: #1c1c1c;
}

.cursor__dot2.light-ring {
  width: 6em;
  height: 6em;
  border-color: #e2bceb;
}

.paragraph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cursor__text {
  margin-bottom: 0px;
  color: transparent;
  font-size: 1em;
  line-height: 1.6;
}

.cursor__text.make-white {
  color: #fff;
}

.body {
  background-color: #ff5a6e;
  color: transparent;
}

.link-block {
  display: none;
}

.trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.trigger__circle {
  position: absolute;
  width: 14.58em;
  height: 14.58em;
  border: 0.2em solid #000;
  border-radius: 50%;
}

.trigger__circle.color-black {
  border-color: #1c1c1c;
}

.trigger__line.is--2 {
  width: 3em;
  height: 0.2em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  background-color: #1c1c1c;
}

.trigger__line.is--2.bg-black {
  background-color: #1c1c1c;
}

.trigger__line.is--1 {
  width: 3em;
  height: 0.2em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  background-color: #1c1c1c;
}

.trigger__line.is--1.bg-black {
  background-color: #1c1c1c;
}

.trigger__line.is--1.rotate-line {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nft-collage-section {
  position: static;
  padding-top: 130px;
  padding-bottom: 140px;
  background-color: #f8e7c2;
}

.menu {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  display: none;
}

.background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 101%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.background__fill {
  width: 100%;
  height: 100%;
  background-color: #99cbd7;
}

.background-menu {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1200%;
  height: 100vh;
  max-width: 1380px;
  margin-right: auto;
  margin-left: auto;
  padding: 4.06em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu__contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu__items {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-left: 4.06em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.nav-link {
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  cursor: pointer;
}

.nav-link:hover {
  background-image: none;
  font-style: italic;
}

.nav-link__line {
  width: 0em;
  height: 0.03em;
  margin-right: 5px;
  background-color: #1c1c1c;
}

.nav-link__contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.nav-link__text {
  margin-bottom: 0px;
  font-family: 'Permanent Marker', sans-serif;
  color: #1c1c1c;
  font-size: 0.6em;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-decoration: none;
}

.nav-link__num {
  margin-top: 0.1em;
  margin-bottom: 0px;
  margin-left: 1.2em;
  font-family: 'Permanent Marker', sans-serif;
  color: #1c1c1c;
  font-size: 0.18em;
  line-height: 1;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-decoration: none;
}

.background__lottie {
  display: none;
  width: 100%;
}

.main-grid-2 {
  position: relative;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.main-grid-2.padding-y {
  padding-top: 100px;
  padding-bottom: 180px;
}

.footer-wrapper-2 {
  width: 100%;
  background-color: #e2bceb;
}

.main-button-2 {
  padding: 20px 40px;
  border: 3px solid #000;
  border-radius: 100px;
  background-color: #f8e7c2;
  box-shadow: 6px 6px 0 0 #000;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.main-button-2:hover {
  box-shadow: 1px 1px 0 0 #000;
}

.main-button-2.small {
  padding: 12px 25px;
  background-color: transparent;
  color: #000;
  font-size: 18px;
}

.title-tag-2 {
  position: relative;
  bottom: -1.4vh;
  z-index: 5;
  padding: 8px 11px;
  border-radius: 4px;
  background-color: #ff5a6e;
  -webkit-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
  font-family: Tanker, sans-serif;
  color: #ffd88e;
  font-size: 22px;
  font-weight: 400;
}

.timeline_definition-wrapper {
  padding: 16px;
  border-radius: 16px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.overlay-fade-bottom {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 80px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#0a0a0a), to(rgba(10, 10, 10, 0)));
  background-image: linear-gradient(0deg, #0a0a0a, rgba(10, 10, 10, 0));
}

.timeline_component {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.timeline_milestone-badge {
  display: inline-block;
  margin-bottom: 24px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  color: #161616;
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.timeline_quote-title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.overlay-fade-top {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 80px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0a0a0a), to(rgba(10, 10, 10, 0)));
  background-image: linear-gradient(180deg, #0a0a0a, rgba(10, 10, 10, 0));
}

.text-colour-lightgrey {
  color: hsla(0, 0%, 100%, 0.65);
}

.timeline_item {
  position: relative;
  z-index: 2;
  display: -ms-grid;
  display: grid;
  padding-top: 80px;
  padding-bottom: 80px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 180px 1fr;
  grid-template-columns: 1fr 180px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.timeline_quote {
  margin-bottom: 16px;
  color: #fff;
  font-size: 14px;
}

.timeline_badge {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.timeline_link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.6;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}

.timeline_link:hover {
  opacity: 1;
}

.timeline_quote-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 16px;
}

.timeline_quote-text-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.timeline_progress {
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: #414141;
}

.timeline_centre {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-colour-white {
  color: #fff;
}

.timeline_progress-bar {
  position: fixed;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 50vh;
  width: 3px;
  height: 50vh;
  background-color: #fff;
  background-image: none;
}

.timeline_circle {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  width: 15px;
  height: 15px;
  max-height: 15px;
  max-width: 15px;
  min-height: 15px;
  min-width: 15px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 8px #0a0a0a;
}

.margin-bottom-medium-2 {
  margin-bottom: 32px;
}

.timeline_quote-image {
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 8px;
}

.timeline_image-wrapper {
  overflow: hidden;
  border-radius: 12px;
  background-image: linear-gradient(138deg, #000, transparent 28%), url('images/Image-Background.png');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.margin-bottom-xlarge {
  margin-bottom: 56px;
}

.timeline_date-text {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  font-family: 'Permanent Marker', sans-serif;
  color: #1c1c1c;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.03em;
}

.timeline_left {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.timeline_text {
  font-family: 'Architects Daughter', sans-serif;
  color: #1c1c1c;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.timeline_component-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1120px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-5 {
  width: 90vw;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
}

.section-timeline {
  position: relative;
  z-index: -3;
  background-color: #99cbd7;
}

.timeline_progress-2 {
  position: absolute;
  z-index: -2;
  width: 3px;
  height: 100%;
  background-color: #414141;
}

.timeline_progress-bar-2 {
  position: fixed;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 50vh;
  z-index: -1;
  width: 3px;
  height: 50vh;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff7448), color-stop(51%, #ff4848), to(#6248ff));
  background-image: linear-gradient(180deg, #ff7448, #ff4848 51%, #6248ff);
}

.timeline-heading {
  padding-bottom: 100px;
  background-color: #f8e7c2;
}

.padding-vertical-xlarge {
  padding-top: 120px;
  padding-bottom: 120px;
}

.timeline-main_heading-wrapper {
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  text-align: center;
}

.paragraph-large {
  font-size: 20px;
  letter-spacing: -0.02em;
}

.timeline {
  position: relative;
  z-index: -3;
  padding-bottom: 0px;
  background-color: #f8e7c2;
}

.timeline-heading-right {
  font-family: 'Permanent Marker', sans-serif;
}

.overflow-hidden {
  overflow: hidden;
}

.faq_wrap-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-columns: 20rem 1fr;
  grid-template-columns: 20rem 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.faq-image_hover {
  display: none;
}

.faq_aswer-wrap_spacer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.faq_trigger {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  -ms-grid-columns: 1fr 2rem;
  grid-template-columns: 1fr 2rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  cursor: pointer;
}

.faq_answer-wrap {
  overflow: hidden;
  padding-right: 3rem;
}

.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('images/webyst-hero-background.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.margin-bottom {
  margin-top: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

.heading-xlarge-2 {
  font-size: 4rem;
  line-height: 1.1;
}

.faq_heading-wrap {
  font-size: 1.25rem;
  font-weight: 500;
}

.page-padding {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.faq_element {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.125rem solid #111;
}

.faq_element.last {
  margin-bottom: 0rem;
}

.text-size-medium {
  font-size: 1.25rem;
}

.padding-vertical {
  padding-right: 0rem;
  padding-left: 0rem;
}

.faq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f8e7c2;
  background-image: none;
  background-size: auto;
}

.accordion {
  display: block;
  width: 66.66%;
  margin-right: auto;
  margin-bottom: -24px;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.accordion.js-accordion {
  margin-bottom: 100px;
}

.accordion-body__contents {
  margin-top: 8px;
  opacity: 1;
  font-family: 'Architects Daughter', sans-serif;
  color: #1c1c1c;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}

.accordion-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2e323c;
}

.accordion-header.js-accordion-header {
  cursor: pointer;
}

.accordion-body {
  display: block;
  padding-right: 8px;
  padding-left: 64px;
  color: #9096a4;
}

.accordion-body.js-accordion-body {
  padding-bottom: 20px;
  padding-left: 0px;
  color: #9096a4;
}

.accordion__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
  padding: 20px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(46, 50, 60, 0.09);
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
  text-align: left;
  text-decoration: none;
}

.accordion__item:hover {
  box-shadow: 0 3px 9px 0 rgba(46, 50, 60, 0.09);
}

.accordion__item.js-accordion-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #1c1c1c;
  border-radius: 0px;
  background-color: transparent;
  color: #fff;
}

.accordion__item.js-accordion-item.active {
  border-style: solid;
  border-width: 1px;
  border-color: #1c1c1c;
  border-radius: 10px;
  background-color: transparent;
  cursor: default;
}

.faq-button {
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 3px;
  background-color: #ffb223;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.faq-button:hover {
  background-color: #ffd17b;
}

.faq-wrapper {
  max-width: 1200px;
  background-color: transparent;
}

.accordion_item-image {
  width: 40px;
}

.question-text {
  margin-top: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Permanent Marker', sans-serif;
  color: #1c1c1c;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.team {
  position: relative;
  padding-top: 120px;
  padding-bottom: 140px;
  background-color: #e2bceb;
}

.team-members-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 120px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.single-team-member {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 8px;
}

.single-team-member.last-team {
  margin-left: 30px;
}

.team-content {
  padding-top: 20px;
}

.team-member-name {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 24px;
  line-height: 1.4;
}

.team-member-designation {
  font-family: Inconsolata, monospace;
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.team-member-info {
  padding-top: 10px;
  font-family: 'Architects Daughter', sans-serif;
  font-weight: 400;
}

.team-member-avatar {
  width: 250px;
  border-style: solid;
  border-width: 5px;
  border-color: #85eca9;
  border-radius: 50%;
  -webkit-transition: border-color 350ms ease, -webkit-transform 350ms ease;
  transition: border-color 350ms ease, -webkit-transform 350ms ease;
  transition: transform 350ms ease, border-color 350ms ease;
  transition: transform 350ms ease, border-color 350ms ease, -webkit-transform 350ms ease;
}

.empty-space-220 {
  position: relative;
  z-index: -3;
  height: 200px;
  background-color: #f8e7c2;
}

.whitepaper {
  position: relative;
  padding-top: 0px;
  padding-bottom: 140px;
  background-color: #e2bceb;
}

.primary-heading-3 {
  margin-bottom: 100px;
  font-family: 'Permanent Marker', sans-serif;
  font-size: 40px;
  line-height: 1.4;
  text-align: center;
}

.whitepaper-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.upcoming-projects {
  position: relative;
  background-color: #e2bceb;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.copyright-text {
  font-family: 'Architects Daughter', sans-serif;
  color: #1c1c1c;
  font-size: 18px;
  line-height: 1.35;
  font-weight: 400;
}

.join-text {
  margin-bottom: 10px;
  font-family: Inconsolata, monospace;
}

.nav-menus {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.header {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-color: transparent;
}

@media screen and (min-width: 1280px) {
  .hero {
    min-height: 960px;
  }

  .primary-heading-2 {
    font-size: 90px;
  }

  .nav-link {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .nav-link {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1920px) {
  .hero {
    height: 80vh;
  }

  .menu {
    display: none;
  }

  .nav-link {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 25px;
    padding-left: 25px;
  }

  .container.padding-top-large {
    padding-top: 60px;
  }

  .container.padding-top-large.title-layout-2 {
    padding-top: 40px;
  }

  .inner-padding-large {
    padding-top: 12vh;
    padding-bottom: 12vh;
  }

  .inner-padding-medium {
    padding-top: 8vh;
    padding-bottom: 8vh;
  }

  .inner-padding-small {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }

  .inner-padding-xsmall {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .padding-bottom-large {
    padding-bottom: 12vh;
  }

  .padding-bottom-medium {
    padding-bottom: 8vh;
  }

  .padding-bottom-small {
    padding-bottom: 4vh;
  }

  .margin-bottom-large {
    margin-bottom: 12vh;
  }

  .margin-bottom-medium {
    margin-bottom: 8vh;
  }

  .margin-bottom-small {
    margin-bottom: 4vh;
  }

  .margin-bottom-xsmall {
    margin-bottom: 2vh;
  }

  .center-wrapper.inner-column.video-wrapper {
    padding-top: 3vh;
  }

  .center-wrapper.right {
    padding-right: 30px;
  }

  .content-wrapper.detail {
    padding-left: 25px;
  }

  .content-wrapper.box {
    padding-left: 30px;
  }

  .content-wrapper.bg-yellow {
    z-index: 5;
  }

  .content-wrapper.list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50vh;
    margin-bottom: 5vh;
    padding: 30px;
  }

  .overflow-wrapper.type-hero {
    padding-top: 10vh;
  }

  .main-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .main-grid.full-page.overflow {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .main-grid.hero {
    -ms-grid-rows: 1fr auto;
    grid-template-rows: 1fr auto;
  }

  .main-grid.full-list {
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
  }

  .heading-large {
    font-size: 45px;
  }

  .heading-large.hero {
    font-size: 80px;
  }

  .heading-large.repeat {
    display: none;
  }

  .heading-large.white-text {
    font-size: 45px;
    line-height: 1.3;
  }

  .heading-medium.margin-bottom-xsmall {
    font-size: 30px;
  }

  .cover-image.rounded {
    overflow: hidden;
    border: 5px solid #000;
    border-radius: 20px;
  }

  .heading-xlarge {
    font-size: 120px;
  }

  .heading-xlarge.split {
    font-size: 70px;
  }

  .heading-xlarge.hover {
    display: none;
  }

  .heading-xlarge.hero {
    font-size: 100px;
  }

  .sticky-wrapper.home-page {
    position: relative;
    top: 0px;
  }

  .sticky-wrapper.specials {
    position: relative;
  }

  .nav-grid {
    padding-right: 25px;
    padding-left: 25px;
  }

  .brand {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .image-move {
    display: -ms-grid;
    display: grid;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .inner-image {
    position: static;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .inner-image.back {
    -webkit-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }

  .inner-image.middle {
    -webkit-transform: rotate(14deg);
    -ms-transform: rotate(14deg);
    transform: rotate(14deg);
  }

  .inner-image.front {
    -webkit-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }

  .main-button.edit-btn {
    margin-top: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
  }

  .heading-wrapper {
    z-index: 3;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .link-hero {
    position: relative;
    bottom: 0%;
    overflow: hidden;
    width: 33vw;
    height: 33vw;
  }

  .cta-label {
    width: 100%;
    height: 100%;
  }

  .shape-button.card {
    display: none;
  }

  .hero-section-wrapper {
    padding-top: 15vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .grid.top-nav {
    display: none;
  }

  .header-page-wrapper {
    padding-top: 10vh;
  }

  .header-page-wrapper.header {
    padding-top: 15vh;
  }

  .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 90px;
  }

  .collection-list {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .container-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .container-wrapper.inner-padding-medium.header {
    padding-top: 15vh;
  }

  .container-wrapper.header-page {
    padding-top: 180px;
  }

  .container-wrapper.full-page {
    min-height: auto;
  }

  .container-wrapper.full-page.header {
    padding-top: 10vh;
  }

  .image-wrapper.outline {
    margin-right: auto;
    margin-left: auto;
  }

  .image-wrapper.outline.rotate-10 {
    max-width: 80vw;
  }

  .image-wrapper.outline.rotate--5 {
    max-width: 80vw;
  }

  .image-wrapper.outline.cc-circle {
    width: 75vw;
    height: 75vw;
  }

  .image-wrapper.shadow {
    width: 75vw;
    margin-right: auto;
    margin-left: auto;
  }

  .image-wrapper.header-post {
    border-bottom: 3px solid #000;
    border-right-style: none;
  }

  .image-wrapper.food-post {
    height: 50vh;
    border-bottom: 4px solid #000;
    border-right-style: none;
  }

  .image-wrapper.section-outline {
    height: 50vh;
    max-width: 60vw;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-large {
    font-size: 36px;
  }

  .padding-top-large {
    padding-top: 12vh;
  }

  .padding-top-medium {
    padding-top: 8vh;
  }

  .tabs-menu {
    position: relative;
    top: 0px;
    min-height: auto;
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .tabs {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .tab {
    margin-right: 2px;
    margin-left: 2px;
    font-size: 24px;
  }

  .combo-img-wrapper.hero {
    max-width: 80vw;
  }

  .collection-item {
    position: relative;
  }

  .card-wrapper {
    width: 90vw;
  }

  .flex-card:hover {
    border-color: transparent;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .flex-card.content-four:hover {
    background-color: transparent;
  }

  .card-grid-wrapper:hover {
    background-color: transparent;
  }

  .circle-wrapper {
    width: 25vw;
    height: 25vw;
    border-radius: 100%;
  }

  .design-detail {
    top: -33%;
    right: 0%;
  }

  .design-detail.tab-design-details {
    top: -25%;
  }

  .title-tag.page {
    left: 35px;
  }

  .image-frame-gallery.first {
    width: 60vw;
  }

  .image-frame-gallery.second {
    width: 80vw;
  }

  .image-frame-gallery.third {
    width: 78vw;
  }

  .image-frame-gallery.rotate {
    width: 90vw;
    height: 45vh;
  }

  .image-frame-gallery.back {
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    display: none;
    width: 100%;
    height: 100%;
  }

  .link-block-heading {
    height: 78px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .hover-image-wrapper {
    position: relative;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 30vw;
    height: 100%;
  }

  .section-background.full-hero {
    position: relative;
    display: none;
    border-style: none;
  }

  .hero-rotate-image {
    width: 70vw;
    height: 45vh;
  }

  .back-shadow {
    width: 90%;
    height: 90%;
  }

  .flex-ticker.list {
    padding-left: 30px;
  }

  .hero-image-container {
    max-width: 60vw;
  }

  .track.hero-three {
    height: auto;
  }

  .rail-hero-skew {
    left: 38.3vh;
  }

  .heading-wrapper-flex {
    z-index: 3;
    padding-left: 10vw;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: center;
  }

  .skew-image-wrapper {
    height: 25vh;
  }

  .heading-large-wrapper {
    height: auto;
  }

  .hover-ticker-card {
    display: none;
  }

  .link-content-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 25px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .divider.version-01.mobile-v1 {
    bottom: 0%;
  }

  .link-cards:hover {
    background-color: transparent;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .screenshot-wrapper {
    height: auto;
  }

  .screenshot-wrapper.square {
    height: 245px;
  }

  .main-heading-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-divider {
    display: none;
  }

  .column.full-height {
    position: relative;
    height: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .column.form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 3px solid #000;
    border-right-style: none;
  }

  .span-copyright {
    top: 0vh;
    font-size: 100px;
  }

  .span-copyright.medium {
    top: -2.7vh;
    display: inline-block;
    font-size: 60px;
  }

  .box-center-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .collection-list-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .collection-list-grid._4-col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .collection-list-grid._2-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .collection-list-grid-wrapper {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .figure-wrapper-heading {
    width: 15vh;
    height: 11vh;
  }

  .figure-wrapper-heading.absolute {
    width: 18vh;
  }

  .paragraph-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .collection-list-wrapper-hero {
    width: 100%;
    padding-left: 30px;
  }

  .form-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .container-wrapper-2 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .navbar-container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .social-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .hero {
    min-height: 920px;
  }

  .hero-content-wrapper {
    margin-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-gif {
    width: 300px;
  }

  .primary-heading-2 {
    margin-bottom: 30px;
    font-size: 55px;
    line-height: 1em;
  }

  .hero-subtext {
    margin-bottom: 0px;
    font-size: 20px;
  }

  .controls {
    bottom: -5%;
  }

  .control.prev-splide {
    width: 5em;
    height: 5em;
  }

  .control.next-splide {
    width: 5em;
    height: 5em;
  }

  .control__arrow {
    width: 2em;
    height: 1.3em;
  }

  .control__arrow.is--flipped {
    height: 1.7em;
  }

  .cursor {
    display: none;
  }

  .trigger__circle {
    width: 10em;
    height: 10em;
  }

  .nft-collage-section {
    padding-top: 60px;
  }

  .main-grid-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .main-grid-2.padding-y {
    padding-top: 40px;
    padding-bottom: 120px;
  }

  .timeline_item {
    -ms-grid-columns: 1fr 120px 1fr;
    grid-template-columns: 1fr 120px 1fr;
  }

  .timeline_date-text {
    font-size: 30px;
  }

  .timeline_text {
    font-size: 20px;
  }

  .timeline-heading-right {
    margin-top: 0px;
    font-size: 30px;
  }

  .faq_wrap-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .heading-xlarge-2 {
    font-size: 3.5rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .faq {
    padding-top: 60px;
  }

  .accordion {
    width: 83.33%;
    padding-right: 8px;
    padding-left: 8px;
  }

  .team {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .team-members-wrapper {
    padding-bottom: 60px;
  }

  .empty-space-220 {
    height: 140px;
  }

  .whitepaper {
    padding-bottom: 130px;
  }

  .primary-heading-3 {
    margin-bottom: 100px;
  }

  .join-text {
    text-align: center;
  }

  .nav-menus {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    padding-top: 40px;
    padding-bottom: 60px;
    padding-left: 20px;
    background-color: #f8e7c2;
  }

  .menu-button-2 {
    padding: 5px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    border-style: solid;
    border-width: 2px;
    border-color: #1c1c1c;
    border-radius: 10px;
  }

  .menu-button-2.w--open {
    background-color: #f8e7c2;
  }

  .icon {
    color: #1c1c1c;
    font-size: 40px;
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .center-wrapper.inner-column.video-wrapper {
    height: 50vh;
  }

  .main-paragraph.text-medium {
    position: relative;
    z-index: 1;
  }

  .text-medium {
    font-size: 24px;
  }

  .heading-large {
    margin-top: 0vh;
    margin-bottom: 30px;
    font-size: 40px;
  }

  .heading-large.white-text {
    font-size: 40px;
  }

  .heading-medium.margin-bottom-xsmall {
    font-size: 25px;
  }

  .heading-regular {
    font-size: 20px;
  }

  .heading-xlarge {
    font-size: 70px;
  }

  .image-move {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .tabs-menu {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tab {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 18px;
  }

  .design-detail {
    top: -50%;
  }

  .span-image-first {
    border-width: 3px;
    border-radius: 6px;
  }

  .title-tag {
    font-size: 16px;
  }

  .span-image-two {
    border-width: 3px;
    border-radius: 6px;
  }

  .rail-hero-skew {
    left: 37vh;
  }

  .heading-wrapper-flex {
    padding-left: 5vw;
  }

  .skew-image-wrapper {
    height: 20vh;
    border-radius: 14px;
  }

  .design-detail-arrow {
    bottom: -36%;
  }

  .link-cards {
    font-size: 14px;
  }

  .span-copyright.medium {
    top: 0vh;
    font-size: 35px;
  }

  .main-paragraph-2.text-medium {
    position: relative;
    z-index: 1;
  }

  .main-paragraph-2.text-medium.margin-bottom-small {
    margin-bottom: 0vh;
    font-size: 20px;
  }

  .sticky-nav {
    position: relative;
  }

  .nav-grid-2 {
    text-align: center;
  }

  .navbar-container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .social-link.footer-social-link {
    display: block;
  }

  .social-link-image {
    width: 30px;
  }

  .hero {
    min-height: 900px;
  }

  .hero-gif {
    width: 250px;
  }

  .primary-heading-2 {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1.3em;
  }

  .section-subtitle {
    font-size: 20px;
  }

  .splide__slide {
    width: 100%;
    min-height: 30em;
  }

  .control.prev-splide {
    width: 6em;
    height: 6em;
  }

  .control.next-splide {
    width: 6em;
    height: 6em;
  }

  .control__arrow {
    width: 2.7em;
    height: 1.7em;
  }

  .control__arrow.is--flipped {
    height: 2.5em;
  }

  .trigger {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .trigger__circle {
    position: absolute;
    width: 8em;
    height: 8em;
  }

  .main-grid-2.padding-y {
    padding-bottom: 90px;
  }

  .title-tag-2 {
    font-size: 16px;
  }

  .timeline_item {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 50px;
    -ms-grid-columns: 64px 1fr;
    grid-template-columns: 64px 1fr;
  }

  .timeline_quote-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .timeline_progress {
    left: 6px;
  }

  .timeline_centre {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .timeline_quote-image {
    margin-top: 24px;
    margin-right: 0px;
  }

  .margin-bottom-xlarge {
    margin-bottom: 48px;
  }

  .timeline_date-text {
    margin-bottom: 24px;
    font-size: 30px;
  }

  .timeline_left {
    text-align: left;
  }

  .timeline_text {
    font-size: 20px;
  }

  .timeline_progress-2 {
    left: 6px;
  }

  .padding-vertical-xlarge {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .paragraph-large {
    font-size: 18px;
  }

  .timeline-heading-right {
    font-size: 26px;
  }

  .faq_wrap-grid {
    grid-row-gap: 3rem;
  }

  .faq_answer-wrap {
    padding-right: 0rem;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .heading-xlarge-2 {
    font-size: 2.5rem;
  }

  .page-padding {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .text-size-medium {
    font-size: 1.125rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .accordion {
    width: 100%;
    margin-bottom: -16px;
  }

  .accordion__item {
    margin-bottom: 16px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .question-text {
    margin-top: 8px;
  }

  .team-members-wrapper {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .single-team-member {
    width: 100%;
    margin-bottom: 20px;
  }

  .single-team-member.last-team {
    margin-left: 0px;
  }

  .empty-space-220 {
    overflow: hidden;
    height: 110px;
  }

  .whitepaper {
    padding-bottom: 90px;
  }

  .primary-heading-3 {
    font-size: 30px;
  }

  .copyright-text {
    text-align: center;
  }

  .join-text {
    font-size: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .container.padding-top-large.title-layout-2 {
    padding-top: 0px;
  }

  .center-wrapper.inner-column.video-wrapper {
    padding-top: 0vh;
    padding-bottom: 0vh;
  }

  .center-wrapper.box-column {
    padding: 0vh;
  }

  .content-wrapper.detail {
    padding-left: 25px;
  }

  .main-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .main-grid.full-page {
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
  }

  .main-grid.full-page.overflow {
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
  }

  .main-grid.hero {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .main-grid.full-list {
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
  }

  .main-grid.large-gap {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .main-paragraph.text-medium {
    position: relative;
    z-index: 4;
  }

  .main-paragraph.text-large {
    font-size: 36px;
  }

  .heading-large {
    margin-top: 4vh;
    -ms-grid-rows: 1px;
    grid-template-rows: 1px;
    font-size: 30px;
  }

  .heading-large.white-text {
    margin-top: 3vh;
    font-size: 30px;
  }

  .heading-medium {
    font-size: 34px;
  }

  .heading-medium.margin-bottom-xsmall {
    font-size: 20px;
  }

  .heading-regular {
    font-size: 26px;
  }

  .heading-xlarge.split {
    font-size: 44px;
    text-align: left;
  }

  .sticky-wrapper.bg-yellow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-wrapper {
    margin-top: 0vh;
  }

  .nav-grid {
    -ms-grid-columns: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
  }

  .brand {
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .image-move {
    height: 50vh;
    max-height: 400px;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
  }

  .main-button {
    font-size: 20px;
    font-weight: 400;
  }

  .main-button.edit-btn {
    padding: 12px 30px;
  }

  .link-hero {
    bottom: 0%;
    width: 60vw;
    height: 60vw;
  }

  .shape-button.small {
    height: 32px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .shape-button.center {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: -3vh;
  }

  .shape-button.medium {
    width: auto;
    height: 50px;
  }

  .hero-section-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 12vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header-page-wrapper.header {
    padding-top: 18vh;
  }

  .nav-link {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 60px;
    line-height: 0.8;
  }

  .container-wrapper.inner-padding-medium.header {
    padding-top: 18vh;
  }

  .container-wrapper.header-page {
    padding-top: 120px;
  }

  .container-wrapper.header-page.home {
    padding-top: 0vh;
    padding-bottom: 2vh;
  }

  .image-wrapper.outline {
    border-width: 4px;
    border-radius: 14px;
  }

  .image-wrapper.outline.rotate-10 {
    max-width: 75vw;
  }

  .image-wrapper.outline.rotate--5 {
    max-width: 70vw;
  }

  .image-wrapper.header-post {
    height: 35vh;
  }

  .image-wrapper.food-post {
    height: 35vh;
  }

  .image-wrapper.circle {
    width: 55vw;
    height: 55vw;
  }

  .image-wrapper.section-outline {
    height: 35vh;
  }

  .image-wrapper.outlined {
    width: 80vw;
    height: 35vh;
  }

  .text-large {
    font-size: 26px;
  }

  .tabs-menu {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .combo-img-wrapper {
    border-radius: 10px;
  }

  .combo-img-wrapper.hero {
    max-height: 30vh;
    max-width: 70vw;
    opacity: 1;
  }

  .card-wrapper {
    height: 60vh;
  }

  .thumb-card.shadow {
    margin-bottom: 0px;
  }

  .thumb-card.shadow.medium {
    height: 350px;
  }

  .card-grid-wrapper {
    padding: 0px;
  }

  .card-grid-wrapper:hover {
    background-color: transparent;
  }

  .circle-wrapper {
    width: 60vw;
    height: 60vw;
    border-width: 4px;
  }

  .design-detail {
    top: -87%;
    right: -7%;
  }

  .design-detail.mobile-v2 {
    top: -152%;
  }

  .span-image-first {
    border-width: 3px;
    border-radius: 6px;
  }

  .image-frame-gallery {
    width: 75vw;
    height: auto;
  }

  .image-frame-gallery.first {
    width: 80vw;
    height: auto;
  }

  .image-frame-gallery.second {
    width: 80vw;
    height: auto;
  }

  .image-frame-gallery.third {
    width: 80vw;
    height: auto;
  }

  .image-frame-gallery.rotate {
    height: 30vh;
  }

  .span-image-two {
    border-width: 3px;
    border-radius: 6px;
  }

  .link-block-heading {
    height: auto;
  }

  .hover-image-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .hero-rotate-image {
    width: 80vw;
    height: 25vh;
  }

  .hero-image-container {
    bottom: 15vh;
    width: 75vw;
    max-width: none;
  }

  .frame-image {
    height: 45vh;
  }

  .frame-image.sec-three {
    height: 35vh;
  }

  .frame-image.sec-four {
    width: 70vw;
    height: 30vh;
    max-width: none;
  }

  .rail-hero-skew {
    left: 20vh;
    width: 44vw;
    -webkit-transform: rotate(23deg);
    -ms-transform: rotate(23deg);
    transform: rotate(23deg);
  }

  .text-span {
    left: 0vw;
  }

  .text-span-two {
    left: 0vw;
  }

  .text-span-three {
    left: 0vw;
  }

  .text-span-four {
    left: 0vw;
  }

  .heading-wrapper-flex {
    padding-left: 25px;
  }

  .skew-image-wrapper {
    height: 22vh;
    margin-bottom: 1vh;
    border-width: 3px;
    border-radius: 11px;
  }

  .design-detail-arrow {
    left: 25%;
    top: auto;
    right: auto;
    bottom: -101%;
  }

  .link-content-section.inner-padding-large {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .link-content-heading {
    height: 12vh;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 25px;
  }

  .divider.version-01 {
    background-position: 50% 50%;
    background-size: cover;
  }

  .divider.version-02 {
    background-position: 50% 50%;
    background-size: cover;
  }

  .divider.version-03 {
    background-position: 50% 50%;
    background-size: cover;
  }

  .divider.version-02 {
    background-position: 50% 50%;
    background-size: cover;
  }

  .divider.container-divider {
    background-position: 50% 50%;
    background-size: cover;
  }

  .link-cards {
    font-size: 13px;
    line-height: 1;
  }

  .screenshot-wrapper.square {
    height: 150px;
  }

  .alt-wrapper {
    padding: 15px 10px;
  }

  .png-grid {
    height: 100%;
    min-height: auto;
  }

  .ticker-heading-wrapper {
    left: 0%;
    top: -5vh;
    right: 0%;
    bottom: auto;
    width: 60vw;
    margin-right: auto;
    margin-left: auto;
    -webkit-transform: translate(-2vw, 2vh) rotate(-10deg);
    -ms-transform: translate(-2vw, 2vh) rotate(-10deg);
    transform: translate(-2vw, 2vh) rotate(-10deg);
  }

  .column.form {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .card-grid {
    width: 100%;
  }

  .collection-list-grid._4-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .collection-list-grid-wrapper {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .figure-wrapper-heading {
    width: 10vh;
    height: 8vh;
  }

  .paragraph-wrapper.col {
    padding-top: 30px;
  }

  .main-paragraph-2.margin-bottom-xsmall {
    font-size: 16px;
  }

  .main-paragraph-2.text-medium {
    position: relative;
    z-index: 4;
  }

  .main-paragraph-2.text-medium.margin-bottom-small {
    font-size: 16px;
  }

  .container-wrapper-2.inner-page {
    padding-top: 3vh;
  }

  .container-3 {
    text-align: center;
  }

  .grid-2 {
    padding-bottom: 1vh;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .grid-3 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .sticky-nav {
    padding: 0px;
  }

  .nav-grid-2 {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }

  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }

  .nav-link-2 {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }

  .nav-logo {
    font-size: 25px;
  }

  .nav-logo.footer-logo {
    font-size: 30px;
  }

  .hero {
    min-height: 930px;
  }

  .hero-content-wrapper {
    margin-top: 30px;
  }

  .primary-heading-2 {
    font-size: 30px;
  }

  .hero-subtext {
    font-size: 16px;
  }

  .text-block-2 {
    font-size: 18px;
  }

  .section-subtitle {
    padding-bottom: 0px;
    font-size: 16px;
  }

  .control.prev-splide {
    width: 7em;
    height: 7em;
  }

  .control.next-splide {
    width: 7em;
    height: 7em;
  }

  .nft-collage-section {
    padding-top: 0px;
    padding-bottom: 140px;
  }

  .main-grid-2 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .main-button-2 {
    font-size: 20px;
    font-weight: 400;
  }

  .timeline_item {
    -ms-grid-columns: 48px 1fr;
    grid-template-columns: 48px 1fr;
  }

  .margin-bottom-medium-2 {
    margin-bottom: 24px;
  }

  .timeline_date-text {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .timeline_text {
    font-size: 16px;
  }

  .timeline-heading {
    padding-bottom: 60px;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .timeline-heading-right {
    font-size: 22px;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .faq {
    padding-top: 0px;
  }

  .accordion-body__contents {
    font-size: 16px;
  }

  .accordion-body {
    padding-left: 56px;
  }

  .accordion-body.js-accordion-body {
    margin-top: 0px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .accordion_item-image {
    width: 30px;
  }

  .question-text {
    margin-bottom: 8px;
    margin-left: 0px;
    padding-right: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 100%;
    -ms-flex: 0 100%;
    flex: 0 100%;
    font-size: 20px;
    line-height: 28px;
  }

  .team-member-name {
    font-size: 20px;
  }

  .team-member-designation {
    font-size: 16px;
  }

  .team-member-info {
    font-size: 16px;
  }

  .empty-space-220 {
    position: relative;
    z-index: 5;
  }

  .primary-heading-3 {
    font-size: 20px;
  }

  .join-text {
    font-size: 18px;
    line-height: 25px;
  }
}

#w-node-_07696ed0-4e2a-15f0-a38e-15e91eb5d739-641355f5 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_6a0c5802-ac8a-90e1-3515-04648c87552d-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57ad-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57ae-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
}

#w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57af-641355f5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 9;
  grid-column-start: span 9;
  -ms-grid-column-span: 9;
  grid-column-end: span 9;
}

#w-node-_7b52b9c1-ecdf-a43c-bcc1-8fff212f2344-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-_7b52b9c1-ecdf-a43c-bcc1-8fff212f2346-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0a88862e-3250-4861-bf6c-96e1adee88c0-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-_0a88862e-3250-4861-bf6c-96e1adee88c2-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a4cb571a-fc41-60a5-6ad1-29c4783da849-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_9e4d908e-8ca4-9c50-085a-e7b2dc1269a8-641355f5 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_999d70c9-7fe1-6157-5cbf-0addbcb3278e-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_999d70c9-7fe1-6157-5cbf-0addbcb32793-641355f5 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb33a-641355f5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb33c-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb342-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb347-641355f5 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb34d-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_966f4345-a733-0af2-0437-ecb2430fb354-641355f5 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_29ecf0f2-210c-15a0-27c0-c7b0e57c7426-641355f5 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_29ecf0f2-210c-15a0-27c0-c7b0e57c742b-641355f5 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_40264d96-923e-613a-3616-afc9ac33830f-ac33830c {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_40264d96-923e-613a-3616-afc9ac338312-ac33830c {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-c120419f-c414-4794-262b-f1ce9d2a6eea-ac33830c {
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_0f44a05e-783e-71ab-4c95-730c23eacfa6-ac33830c {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fd3f7910-34f1-773d-943a-7180b4321102-5e135657 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-5e135657 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd211-5e135657 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 8;
  grid-column-end: 13;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1bbe-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1bc5-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1bd8-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1beb-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2f3204c7-9a94-6ba7-e552-8ff6c2fd109a-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e0f50f47-d195-17ad-24a2-0fe242c60a6d-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1c02-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1c05-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1c0b-689a1bac {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7bd942c7-e5d6-953f-6756-c704689a1c0f-689a1bac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ba278e72-9f7d-45c6-52ac-d7620d315915-0313567d {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-ba278e72-9f7d-45c6-52ac-d7620d315917-0313567d {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-_960d402b-4fd3-506d-4dce-2f322e84981e-0313567d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7fae2db8-a9c9-a4e0-469f-9c9876c0b4a7-0313567d {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ac2e479e-395a-9937-4891-13534c469e5e-0313567d {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_2953a03d-adda-3bf9-3b49-9329a075e292-0313567d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2953a03d-adda-3bf9-3b49-9329a075e294-0313567d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca87-091356ba {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
}

#w-node-df729cb5-0e29-1a40-e75d-a4ab4dd0499b-091356ba {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-afd251d0-fd72-66ad-aa20-4c859522fa24-091356ba {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f2f6647a-3ebb-54df-749b-010e267a71b0-1e1356bb {
  -ms-grid-column-span: 8;
  grid-column-end: 11;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-f2f6647a-3ebb-54df-749b-010e267a71ba-1e1356bb {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-dc56a4fd-36a8-297e-5ec9-d26cf9b6ccc6-1e1356bb {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dc56a4fd-36a8-297e-5ec9-d26cf9b6cccd-1e1356bb {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-dc56a4fd-36a8-297e-5ec9-d26cf9b6ccd0-1e1356bb {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca87-5c1356bc {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
}

#w-node-_49fb17e5-c0b7-1074-d5bc-59ff1dae3657-5c1356bc {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_49fb17e5-c0b7-1074-d5bc-59ff1dae3661-5c1356bc {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca88-a31356bd {
  -ms-grid-column-span: 8;
  grid-column-end: 11;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-d22c8d9d-007a-058f-be06-f970efe452e1-a31356bd {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_31cc682a-d0b8-babf-77fd-424e96f9a635-a31356bd {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937c9d-e81356be {
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937ca0-e81356be {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937ca4-e81356be {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937ca6-e81356be {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937ca8-e81356be {
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 2;
  grid-row-end: 8;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 3;
  grid-column-end: 12;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937caa-e81356be {
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 2;
  grid-row-end: 7;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_38c94974-28b8-2734-f0b4-e6ee2e937cac-e81356be {
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 2;
  grid-row-end: 10;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-_394ae5d4-5fe1-4d1c-70b4-6f2b3e5d816d-e81356be {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404a-e81356be {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 9;
  grid-column-end: 11;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404d-e81356be {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 8;
  grid-column-end: 12;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4050-e81356be {
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 5;
  grid-column-end: 10;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4053-e81356be {
  -ms-grid-column-span: 1;
  grid-column-end: 8;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 0;
  grid-row-end: 5;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-e81356be {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-e81356be {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-e81356be {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-e81356be {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_631ab592-8291-a262-a23b-b341c7643307-e81356be {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_99a6e642-4688-ea53-853a-769574a7b68f-e81356be {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}

#w-node-aee9fc9d-6af9-be71-017c-77682c8efeb9-8c1356c0 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-aee9fc9d-6af9-be71-017c-77682c8efebb-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 7;
  grid-column-end: 8;
}

#w-node-aee9fc9d-6af9-be71-017c-77682c8efed2-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-aee9fc9d-6af9-be71-017c-77682c8efed3-8c1356c0 {
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-aee9fc9d-6af9-be71-017c-77682c8efed4-8c1356c0 {
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_6269dfcd-3260-84e6-fdac-b36987d00265-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
}

#w-node-_6269dfcd-3260-84e6-fdac-b36987d00268-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 4;
  grid-column-end: 13;
}

#w-node-_6269dfcd-3260-84e6-fdac-b36987d00270-8c1356c0 {
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_6269dfcd-3260-84e6-fdac-b36987d00275-8c1356c0 {
  -ms-grid-column-span: 4;
  grid-column-end: 10;
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_666e5a55-7f4a-1575-6949-42afc3886e92-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_666e5a55-7f4a-1575-6949-42afc3886e94-8c1356c0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-d418d117-2469-2e81-b1df-c1114a884db8-8c1356c0 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c016753-6e1356c1 {
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c016754-6e1356c1 {
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c016756-6e1356c1 {
  -ms-grid-column-span: 8;
  grid-column-end: 9;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c016758-6e1356c1 {
  -ms-grid-column-span: 5;
  grid-column-end: 7;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c01675a-6e1356c1 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_5e699df4-3159-f45c-583d-0b582c01675d-6e1356c1 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 7;
  grid-column-end: 8;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6926-6e1356c1 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6928-6e1356c1 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 3;
  grid-column-end: 11;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a692d-6e1356c1 {
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 2;
  grid-row-end: 9;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 3;
  grid-column-end: 11;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6932-6e1356c1 {
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 2;
  grid-row-end: 6;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6934-6e1356c1 {
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 2;
  grid-row-end: 7;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6936-6e1356c1 {
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 2;
  grid-row-end: 10;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6938-6e1356c1 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 2;
  grid-row-end: 6;
}

#w-node-_05ca1d5d-0078-e532-7ddb-bb69d84795c2-fb1356c2 {
  -ms-grid-column-span: 3;
  grid-column-end: 9;
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca67-fb1356c2 {
  -ms-grid-column-span: 8;
  grid-column-end: 11;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca69-fb1356c2 {
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca6e-fb1356c2 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 4;
  grid-column-end: 13;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca70-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 3;
  grid-column-end: 5;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca72-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 3;
  grid-column-end: 12;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca74-fb1356c2 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
}

#w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca76-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 4;
  grid-column-end: 9;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fa5-fb1356c2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fac-fb1356c2 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fb2-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fbc-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fc0-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fca-fb1356c2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fd1-fb1356c2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-f3bd5cf0-8913-1fa4-151f-666135534fd8-fb1356c2 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6342ebd9-cbcf-a993-1110-d0cb1775b3d3-d51356c3 {
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_6342ebd9-cbcf-a993-1110-d0cb1775b3d6-d51356c3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 8;
  grid-column-end: 10;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_515e3543-904d-3470-06a1-3cc373027fda-d51356c3 {
  -ms-grid-column-span: 5;
  grid-column-end: 6;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_515e3543-904d-3470-06a1-3cc373027fdd-d51356c3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 5;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_38227496-9f3d-a000-0861-444c157be54b-d51356c3 {
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-ef3fcbdb-259e-b51f-e7ca-0c3780f31752-d51356c3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_114f0495-008e-5031-35af-91a095f613b6-d51356c3 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4f2c79d6-19b3-6ede-619a-d6513a9c0a83-801356c4 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c083f04f-da7a-a035-087b-05a08bf504eb-801356c4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3aa120c3-9c8f-b550-2ac0-f6480e072c4c-741356c5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_3aa120c3-9c8f-b550-2ac0-f6480e072c4e-741356c5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-a8a46828-3182-f17a-ef14-dd3afc6365ea-741356c5 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-df08c2ee-3a9a-3717-5db3-0d23473ad4ee-4f1356c6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-df08c2ee-3a9a-3717-5db3-0d23473ad4f2-4f1356c6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-df08c2ee-3a9a-3717-5db3-0d23473ad4f5-4f1356c6 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-column-span: 6;
  grid-column-end: 12;
}

#w-node-_4bceed58-3b38-a3ce-1b26-b7e6bce638dd-121356c7 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 8;
  grid-column-end: 11;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f6114f92-76bc-8ed3-7371-65cd3f7d2949-121356c7 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_5ca703cd-e703-fe7a-393f-ca958ef8cbbe-121356c7 {
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_0043e48f-2245-aefe-0552-b932e431f932-821356c8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_0043e48f-2245-aefe-0552-b932e431f934-821356c8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-_4618ce1c-1a14-7102-3e4f-476be609c493-821356c8 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_4618ce1c-1a14-7102-3e4f-476be609c496-821356c8 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_528e86e7-043e-851d-56a8-041bfb9efa8f-261356cc {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_528e86e7-043e-851d-56a8-041bfb9efa99-261356cc {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_204a4689-f6b4-543b-0e7d-bf9b07bc9cea-891356d0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b630227e-da64-7503-cebd-3819286b88fa-891356d0 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d0fce8b9-3575-0872-a64d-db18340e57db-891356d0 {
  -ms-grid-column-span: 5;
  grid-column-end: 6;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-d0fce8b9-3575-0872-a64d-db18340e57df-891356d0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 5;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa94b210-26cd-8712-0d0a-d00d272de8b9-891356d0 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 3;
  grid-column-end: 10;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29f0-891356d0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
}

#w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29f3-891356d0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29fb-891356d0 {
  -ms-grid-column-span: 5;
  grid-column-end: 12;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e2a00-891356d0 {
  -ms-grid-column-span: 3;
  grid-column-end: 9;
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-ab0673af-25f7-dd2c-dc36-a952dc6b1878-891356d0 {
  -ms-grid-column-span: 2;
  grid-column-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_0cdcebf5-b12d-4019-5abf-519fd3420420-891356d0 {
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_7a41614b-fc18-5be9-4366-888d2224e6d6-891356d0 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7a41614b-fc18-5be9-4366-888d2224e6d8-891356d0 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9a8ce3f3-ad2a-2f1c-a473-76a5716184dd-a31356d2 {
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_9a8ce3f3-ad2a-2f1c-a473-76a5716184e6-a31356d2 {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 2;
  grid-row-end: 5;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_9a8ce3f3-ad2a-2f1c-a473-76a5716184e8-a31356d2 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 2;
  grid-column-end: 9;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_9a8ce3f3-ad2a-2f1c-a473-76a5716184ea-a31356d2 {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 2;
  grid-row-end: 5;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-column-span: 3;
  grid-column-end: 13;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf390a-a31356d2 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf390d-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf3912-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4204cb28-107d-3283-e2de-e83f9dbbe54b-a31356d2 {
  -ms-grid-column-span: 3;
  grid-column-end: 5;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf391a-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf391c-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf391e-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_21c33300-49e5-a888-2b92-b4d1b1cf3920-a31356d2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db00-5b1356d3 {
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db01-5b1356d3 {
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db03-5b1356d3 {
  -ms-grid-column-span: 8;
  grid-column-end: 9;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db05-5b1356d3 {
  -ms-grid-column-span: 5;
  grid-column-end: 7;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db07-5b1356d3 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db0a-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 7;
  grid-column-end: 8;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404a-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
}

#w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4053-5b1356d3 {
  -ms-grid-column-span: 1;
  grid-column-end: 8;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-5b1356d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-5b1356d3 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_631ab592-8291-a262-a23b-b341c7643307-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_99a6e642-4688-ea53-853a-769574a7b68f-5b1356d3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}

#w-node-e5a59aa6-3edd-7cae-6278-cef98330a87c-5b1356d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_8572d868-78ec-a825-b90d-3bb294fa0812-5b1356d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_2b73d815-890e-afdf-4c91-ade38f62ba16-5b1356d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef6e-311356d4 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_95eb5124-47a7-e6b1-9eb7-313ee83468b0-311356d4 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef75-311356d4 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef77-311356d4 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef79-311356d4 {
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 2;
  grid-row-end: 8;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 3;
  grid-column-end: 12;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef7b-311356d4 {
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 2;
  grid-row-end: 7;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef7d-311356d4 {
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 2;
  grid-row-end: 10;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 4;
  grid-column-end: 7;
}

#w-node-_73cd3a4a-f2da-4792-b225-e6586f0977b9-311356d4 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_73cd3a4a-f2da-4792-b225-e6586f0977c9-311356d4 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_88f47adb-76da-cb80-056d-ac7dc28d8399-311356d4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_85efd1d1-47f4-ab1f-bba2-f8f0730aa4f7-311356d4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6b8ff5c0-1727-b935-ca03-b3ab9be4e6d7-311356d4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dd795f11-9f1f-fa76-1510-02674fa882cb-311356d4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_98db87dd-691f-e913-7680-20551efcde07-311356d4 {
  -ms-grid-column-span: 5;
  grid-column-end: 6;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_98db87dd-691f-e913-7680-20551efcde0b-311356d4 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 4;
  grid-column-end: 11;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0bc8aff5-9a79-54e4-aeb8-ba27e924416a-051356d5 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_0bc8aff5-9a79-54e4-aeb8-ba27e924416c-051356d5 {
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_236327da-a320-e355-3fb8-95b1139a76c0-051356d5 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_71985ca0-72df-c69d-9d7c-8439f577b206-051356d5 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-e8d4b9aa-837d-0dae-97e3-e1e6b09b6149-051356d5 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_3d0a88b5-76d2-6ec6-85bd-ccac90a22f76-051356d5 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_3d0a88b5-76d2-6ec6-85bd-ccac90a22f7a-051356d5 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_16f01aca-3c93-76b3-04ce-1a234de5b844-051356d5 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_16f01aca-3c93-76b3-04ce-1a234de5b848-051356d5 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_97316323-d643-753a-ad63-0cacbeff0d6f-051356d5 {
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_48225f7c-bfa9-940f-d89c-bf64e68ca0c6-051356d5 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d0bdced1-390a-c65d-2559-e1750dae5920-051356d5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
}

#w-node-_88112c74-f440-8aa2-042c-7aea45f1ad49-051356d5 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-_88bf1c5f-9796-c7ee-758a-9db93c8a32b9-051356d5 {
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 2;
  grid-row-end: 8;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 3;
  grid-column-end: 12;
}

#w-node-_0e642cee-c32b-9056-3881-5d8a58274b35-051356d5 {
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 2;
  grid-row-end: 7;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_305a03e3-b558-5c5e-971c-1857b7189dae-051356d5 {
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 2;
  grid-row-end: 10;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-_61505bba-de36-af02-5678-15c6be1bc291-831356d6 {
  -ms-grid-column-span: 6;
  grid-column-end: 7;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-e79c3cf4-71ed-92b1-74ac-6d62242a762c-831356d6 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe6321-4a1356d7 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe6323-4a1356d7 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe633a-4a1356d7 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe633b-4a1356d7 {
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe633c-4a1356d7 {
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d7d6a083-9288-82c9-58c7-1b8d4bc6a7e4-4a1356d7 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
}

#w-node-_82b05d05-163a-92a0-c4e1-ad0d4d8bbcbc-4a1356d7 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}

#w-node-ec060297-d23b-1b78-628c-5b3bf1bfba2e-141356d8 {
  -ms-grid-column-span: 8;
  grid-column-end: 11;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-ec060297-d23b-1b78-628c-5b3bf1bfba38-141356d8 {
  -ms-grid-column-span: 4;
  grid-column-end: 9;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-a48498a7-1488-3437-ed28-8ee0e44bd9ef-141356d8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 11;
  grid-column-start: 11;
  -ms-grid-column-span: 2;
  grid-column-end: 13;
}

#w-node-_8346d878-a1e1-5382-fa28-de87c3094546-141356d8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-a48498a7-1488-3437-ed28-8ee0e44bda10-141356d8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 5;
  grid-column-end: 10;
}

#w-node-_22ee2dde-e217-a58e-7ed8-4a7dd359b9b8-141356d8 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ee0fb92c-07ab-bffe-a163-589321ff7654-141356d8 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column-span: 1;
  grid-column-end: 11;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_8346d878-a1e1-5382-fa28-de87c3094546-781356d9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-a48498a7-1488-3437-ed28-8ee0e44bda10-781356d9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 5;
  grid-column-end: 12;
}

#w-node-e79c3cf4-71ed-92b1-74ac-6d62242a762c-a61356dd {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-abc02196-e811-bbd0-9ff5-7657018f1902-a61356dd {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb2-dd1356df {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb8-dd1356df {
  -ms-grid-column-span: 1;
  grid-column-end: 11;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9ef1-dd1356df {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9efc-dd1356df {
  -ms-grid-column-span: 1;
  grid-column-end: 11;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_691f16a3-6d61-2183-e540-750cd437fb55-dd1356df {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 10;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_691f16a3-6d61-2183-e540-750cd437fb60-dd1356df {
  -ms-grid-column-span: 1;
  grid-column-end: 11;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68bd-601356e0 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68bf-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c2-601356e0 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 2;
  grid-column-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c5-601356e0 {
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-column-span: 2;
  grid-column-end: 12;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c8-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 5;
  grid-column-end: 10;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68d6-601356e0 {
  -ms-grid-column-span: 5;
  grid-column-end: 6;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68da-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 3;
  grid-column-end: 11;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-601356e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-601356e0 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

#w-node-_631ab592-8291-a262-a23b-b341c7643307-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_99a6e642-4688-ea53-853a-769574a7b68f-601356e0 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}

#w-node-_6028efc3-d369-452d-8b0c-f42572cd8cd4-601356e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_6028efc3-d369-452d-8b0c-f42572cd8cdb-601356e0 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_45e45415-08b5-95d3-7ad2-ab5a83e45f74-2a1356e1 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998109-2a1356e1 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d761cf34-9f52-709a-b1c3-8df667318e47-2a1356e1 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-bed6584c-5297-9392-8143-f20620083935-2a1356e1 {
  -ms-grid-column-span: 4;
  grid-column-end: 6;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_99d881c3-f0b0-b18c-84f4-5ab25f3e656d-2a1356e1 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 5;
  grid-column-end: 12;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998110-2a1356e1 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 4;
  grid-column-end: 6;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998112-2a1356e1 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 4;
  grid-column-end: 12;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998114-2a1356e1 {
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 2;
  grid-row-end: 8;
  -ms-grid-column: 9;
  grid-column-start: 9;
  -ms-grid-column-span: 3;
  grid-column-end: 12;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998116-2a1356e1 {
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 2;
  grid-row-end: 7;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 5;
  grid-column-end: 7;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998118-2a1356e1 {
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 2;
  grid-row-end: 10;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 4;
  grid-column-end: 7;
}

#w-node-_0b76985d-e46c-6d84-bf54-4a90478f97bd-2a1356e1 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 10;
  grid-column-end: 12;
}

#w-node-_593aa2c5-d6eb-2a65-d4ae-295e7c4628e8-2a1356e1 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: 13;
}

#w-node-_29d1c35d-ad90-00d2-4f3b-0cdf35419747-2a1356e1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_29d1c35d-ad90-00d2-4f3b-0cdf35419754-2a1356e1 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_45e45415-08b5-95d3-7ad2-ab5a83e45f74-e21356e2 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d22d5f4c-c287-7707-232e-c5ce89998109-e21356e2 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d761cf34-9f52-709a-b1c3-8df667318e47-e21356e2 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-fe0881d7-483c-4e9a-6c1f-488f948f9ea9-e21356e2 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-f762ea7c-e1be-31e7-9165-6342e528d811-e21356e2 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-ae3179ca-740e-f76a-0701-c3950e44df7c-e21356e2 {
  -ms-grid-column-span: 4;
  grid-column-end: 6;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-f58ee298-bc28-4985-75b8-1d2287652687-e21356e2 {
  -ms-grid-column-span: 6;
  grid-column-end: 10;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-a30dcc32-ee4d-b4ed-b8fc-f71583bd508f-e21356e2 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-a30dcc32-ee4d-b4ed-b8fc-f71583bd5093-e21356e2 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_6c1f3deb-1311-657d-5340-b74496373e3f-e21356e2 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-a95ad4b9-045a-964b-eedc-1d1041f40f62-e21356e2 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-dd012874-56d6-c421-1680-7917af968069-b21356e3 {
  -ms-grid-column-span: 12;
  grid-column-end: 13;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-dd012874-56d6-c421-1680-7917af96806c-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-dd012874-56d6-c421-1680-7917af968071-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8bcd02af-1c24-3633-9dd0-87485375d04b-b21356e3 {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 2;
  grid-column-end: 5;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_47244e47-992b-bf1d-8274-4339c6222543-b21356e3 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 3;
  grid-column-end: 11;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_47244e47-992b-bf1d-8274-4339c6222549-b21356e3 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_40f90b45-2b7c-ae62-0354-2b92b5d612a2-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_40f90b45-2b7c-ae62-0354-2b92b5d612a4-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_40f90b45-2b7c-ae62-0354-2b92b5d612a6-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_40f90b45-2b7c-ae62-0354-2b92b5d612a8-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5cecbe7e-4d88-b62f-4201-c12e611356da-b21356e3 {
  -ms-grid-column-span: 6;
  grid-column-end: 12;
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e2-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e4-b21356e3 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 3;
  grid-column-end: 5;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e6-b21356e3 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-column-span: 3;
  grid-column-end: 13;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_4de558a6-bfcf-1ee5-a330-eff6abe01c08-b21356e3 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 4;
  grid-column-end: 9;
}

#w-node-dd012874-56d6-c421-1680-7917af968079-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dd012874-56d6-c421-1680-7917af96807b-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dd012874-56d6-c421-1680-7917af96807d-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dd012874-56d6-c421-1680-7917af96807f-b21356e3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-fd3f7910-34f1-773d-943a-7180b4321102-b51356e5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-b51356e5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd211-b51356e5 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 8;
  grid-column-end: 13;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd213-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd219-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd21f-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd225-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c58c0872-8ede-8319-f5d4-6388980dd22b-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-feade68b-a3c6-b5fb-beb8-eff1f800a14b-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_4e4a29bd-735d-d61b-d5f5-a6e98fe8658d-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_91c7ce31-eaee-ae7d-f188-ff921e6bd679-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_80f9f928-6b9c-4cdf-0a5d-9bdb180e92f2-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_1596feb1-9435-e5b1-1afd-0c8847c35446-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_34015820-2bc0-121d-8a40-474203a92e98-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c1fa77a3-1061-6396-5950-980470d5e781-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-ba334270-b193-7969-c51c-1a59917b1923-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-a15eabde-e156-104c-79a3-fe6748e95400-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_7d1eccda-d86e-890b-3aad-89a92d9e728c-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-e3472f6e-5c6a-ca69-5eb1-aaa7ec674f75-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_3aaa5b60-d7c4-985a-71a3-5d9beca24ed2-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_23ef4db9-4747-4550-867a-5be21c9a5400-b51356e5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_1501b47a-005f-2358-bdd1-90e3fee49cd1-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 6;
  grid-column-end: 10;
}

#w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c7e-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c81-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 9;
  grid-column-end: 13;
}

#w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c83-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
}

#w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c88-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
}

#w-node-_8d28cfa8-420e-1f9a-cfa4-6a8dd9fd3980-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-_8d28cfa8-420e-1f9a-cfa4-6a8dd9fd3983-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 9;
  grid-column-end: 13;
}

#w-node-_8a467164-3221-77e9-9900-d4d974a1f239-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-_8a467164-3221-77e9-9900-d4d974a1f23c-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 9;
  grid-column-end: 13;
}

#w-node-_554a4b7c-050e-ef5a-75a1-664e7514106d-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
}

#w-node-_554a4b7c-050e-ef5a-75a1-664e75141070-ea1356e9 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 9;
  grid-column-end: 13;
}

#w-node-c5af1464-f9fb-5cb0-4c91-9cf0e966e6e9-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_63ef2506-67ad-13c7-7bf6-0af4c0dca59a-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-c334ef1d-b656-2e67-a24e-4addd5f67ea8-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_0e0b2e16-eeed-09d0-8ea6-dd394cd508fc-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d2d7f95f-0aa6-6e1f-4bc0-aa4a2f90c32d-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_99e6d0a1-8503-e1f4-3e27-36deab029103-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_9066f08f-5f31-3096-c41d-c24347db392b-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_8bee2d0c-1276-42d7-f952-07151b9fa40f-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_375e8865-17b0-aba7-1937-e57e72593d97-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_47c143e3-d3fe-9544-71b7-a35fea4b7989-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_6ee12c58-4749-bb40-9b85-22efac5f2367-ea1356e9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_325151c2-43b6-2a66-8b9a-39b43e1e24db-6e2ba270 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_325151c2-43b6-2a66-8b9a-39b43e1e24e2-6e2ba270 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-bb82b183-06ef-7ee5-df74-93cc5d0c9eba-6e2ba270 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-bb82b183-06ef-7ee5-df74-93cc5d0c9ebf-6e2ba270 {
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_69f4f874-a90d-ff16-22ef-f60bb2d87fb5-6e2ba270 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 8;
  grid-column-start: 8;
  -ms-grid-column-span: 5;
  grid-column-end: 13;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_69f4f874-a90d-ff16-22ef-f60bb2d87fbf-6e2ba270 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}

@media screen and (max-width: 991px) {
  #w-node-_07696ed0-4e2a-15f0-a38e-15e91eb5d739-641355f5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_6a0c5802-ac8a-90e1-3515-04648c87552d-641355f5 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57ad-641355f5 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57ae-641355f5 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_7b52b9c1-ecdf-a43c-bcc1-8fff212f2344-641355f5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_7b52b9c1-ecdf-a43c-bcc1-8fff212f2346-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_0a88862e-3250-4861-bf6c-96e1adee88c0-641355f5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_0a88862e-3250-4861-bf6c-96e1adee88c2-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-a4cb571a-fc41-60a5-6ad1-29c4783da849-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_9e4d908e-8ca4-9c50-085a-e7b2dc1269a8-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_999d70c9-7fe1-6157-5cbf-0addbcb3278e-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_999d70c9-7fe1-6157-5cbf-0addbcb32793-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb33a-641355f5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb33c-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb342-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb347-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb34d-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_966f4345-a733-0af2-0437-ecb2430fb354-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_29ecf0f2-210c-15a0-27c0-c7b0e57c7426-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_29ecf0f2-210c-15a0-27c0-c7b0e57c742b-641355f5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_40264d96-923e-613a-3616-afc9ac33830f-ac33830c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }

  #w-node-c120419f-c414-4794-262b-f1ce9d2a6eea-ac33830c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-fd3f7910-34f1-773d-943a-7180b4321102-5e135657 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-5e135657 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd211-5e135657 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1bbe-689a1bac {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1bc5-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1bd8-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1beb-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_2f3204c7-9a94-6ba7-e552-8ff6c2fd109a-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-e0f50f47-d195-17ad-24a2-0fe242c60a6d-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1c02-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1c05-689a1bac {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1c0f-689a1bac {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-ba278e72-9f7d-45c6-52ac-d7620d315915-0313567d {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-ba278e72-9f7d-45c6-52ac-d7620d315917-0313567d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_7fae2db8-a9c9-a4e0-469f-9c9876c0b4a7-0313567d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-ac2e479e-395a-9937-4891-13534c469e5e-0313567d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca87-091356ba {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-df729cb5-0e29-1a40-e75d-a4ab4dd0499b-091356ba {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-afd251d0-fd72-66ad-aa20-4c859522fa24-091356ba {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-f2f6647a-3ebb-54df-749b-010e267a71b0-1e1356bb {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-f2f6647a-3ebb-54df-749b-010e267a71ba-1e1356bb {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca87-5c1356bc {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_49fb17e5-c0b7-1074-d5bc-59ff1dae3657-5c1356bc {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_49fb17e5-c0b7-1074-d5bc-59ff1dae3661-5c1356bc {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_512a229b-d66a-58a8-58aa-1bfd9b68ca88-a31356bd {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-d22c8d9d-007a-058f-be06-f970efe452e1-a31356bd {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_38c94974-28b8-2734-f0b4-e6ee2e937c9d-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_38c94974-28b8-2734-f0b4-e6ee2e937ca0-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_394ae5d4-5fe1-4d1c-70b4-6f2b3e5d816d-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404a-e81356be {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404d-e81356be {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4050-e81356be {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4053-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-e81356be {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-e81356be {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-e81356be {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_631ab592-8291-a262-a23b-b341c7643307-e81356be {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_99a6e642-4688-ea53-853a-769574a7b68f-e81356be {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-aee9fc9d-6af9-be71-017c-77682c8efeb9-8c1356c0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-aee9fc9d-6af9-be71-017c-77682c8efebb-8c1356c0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-aee9fc9d-6af9-be71-017c-77682c8efed2-8c1356c0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_6269dfcd-3260-84e6-fdac-b36987d00265-8c1356c0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_6269dfcd-3260-84e6-fdac-b36987d00268-8c1356c0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6269dfcd-3260-84e6-fdac-b36987d00270-8c1356c0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_6269dfcd-3260-84e6-fdac-b36987d00275-8c1356c0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-_666e5a55-7f4a-1575-6949-42afc3886e92-8c1356c0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_666e5a55-7f4a-1575-6949-42afc3886e94-8c1356c0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_5e699df4-3159-f45c-583d-0b582c016753-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_5e699df4-3159-f45c-583d-0b582c016754-6e1356c1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_5e699df4-3159-f45c-583d-0b582c016756-6e1356c1 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_5e699df4-3159-f45c-583d-0b582c016758-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_5e699df4-3159-f45c-583d-0b582c01675a-6e1356c1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6926-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6928-6e1356c1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a692d-6e1356c1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6932-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6934-6e1356c1 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
    -ms-grid-row: 6;
    grid-row-start: 6;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6936-6e1356c1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row: 9;
    grid-row-start: 9;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6938-6e1356c1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_05ca1d5d-0078-e532-7ddb-bb69d84795c2-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca67-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca69-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fa5-fb1356c2 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fac-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fb2-fb1356c2 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fbc-fb1356c2 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fc0-fb1356c2 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fca-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fd1-fb1356c2 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fd8-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fdd-fb1356c2 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6342ebd9-cbcf-a993-1110-d0cb1775b3d3-d51356c3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_6342ebd9-cbcf-a993-1110-d0cb1775b3d6-d51356c3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_515e3543-904d-3470-06a1-3cc373027fda-d51356c3 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_515e3543-904d-3470-06a1-3cc373027fdd-d51356c3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_38227496-9f3d-a000-0861-444c157be54b-d51356c3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-ef3fcbdb-259e-b51f-e7ca-0c3780f31752-d51356c3 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_3aa120c3-9c8f-b550-2ac0-f6480e072c4c-741356c5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_3aa120c3-9c8f-b550-2ac0-f6480e072c4e-741356c5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-df08c2ee-3a9a-3717-5db3-0d23473ad4f2-4f1356c6 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-df08c2ee-3a9a-3717-5db3-0d23473ad4f5-4f1356c6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_4bceed58-3b38-a3ce-1b26-b7e6bce638dd-121356c7 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-f6114f92-76bc-8ed3-7371-65cd3f7d2949-121356c7 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_5ca703cd-e703-fe7a-393f-ca958ef8cbbe-121356c7 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_0043e48f-2245-aefe-0552-b932e431f932-821356c8 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_0043e48f-2245-aefe-0552-b932e431f934-821356c8 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_204a4689-f6b4-543b-0e7d-bf9b07bc9cea-891356d0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-b630227e-da64-7503-cebd-3819286b88fa-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-d0fce8b9-3575-0872-a64d-db18340e57db-891356d0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-d0fce8b9-3575-0872-a64d-db18340e57df-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-fa94b210-26cd-8712-0d0a-d00d272de8b9-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29f0-891356d0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29f3-891356d0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e29fb-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_64a5d251-5b4f-025d-8c1a-aa4a966e2a00-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-ab0673af-25f7-dd2c-dc36-a952dc6b1878-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_0cdcebf5-b12d-4019-5abf-519fd3420420-891356d0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_9a8ce3f3-ad2a-2f1c-a473-76a5716184dd-a31356d2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_21c33300-49e5-a888-2b92-b4d1b1cf390a-a31356d2 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_4204cb28-107d-3283-e2de-e83f9dbbe54b-a31356d2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db00-5b1356d3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db01-5b1356d3 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
  }

  #w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db03-5b1356d3 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db05-5b1356d3 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-df3b5e33-dcfd-bf05-e6b9-221ff6e5db07-5b1356d3 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe404a-5b1356d3 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_1ccd6164-4271-c1f5-e9d3-d8ff5fbe4053-5b1356d3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-5b1356d3 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-5b1356d3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-5b1356d3 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-5b1356d3 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_631ab592-8291-a262-a23b-b341c7643307-5b1356d3 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_99a6e642-4688-ea53-853a-769574a7b68f-5b1356d3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef6e-311356d4 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_95eb5124-47a7-e6b1-9eb7-313ee83468b0-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef75-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef77-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef79-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef7b-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a1c9b462-1e0c-ee39-d2a1-8f67ef30ef7d-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_73cd3a4a-f2da-4792-b225-e6586f0977b9-311356d4 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_73cd3a4a-f2da-4792-b225-e6586f0977c9-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_98db87dd-691f-e913-7680-20551efcde07-311356d4 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_98db87dd-691f-e913-7680-20551efcde0b-311356d4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_0bc8aff5-9a79-54e4-aeb8-ba27e924416a-051356d5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_0bc8aff5-9a79-54e4-aeb8-ba27e924416c-051356d5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_236327da-a320-e355-3fb8-95b1139a76c0-051356d5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_71985ca0-72df-c69d-9d7c-8439f577b206-051356d5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e8d4b9aa-837d-0dae-97e3-e1e6b09b6149-051356d5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_3d0a88b5-76d2-6ec6-85bd-ccac90a22f76-051356d5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3d0a88b5-76d2-6ec6-85bd-ccac90a22f7a-051356d5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_16f01aca-3c93-76b3-04ce-1a234de5b844-051356d5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_16f01aca-3c93-76b3-04ce-1a234de5b848-051356d5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_61505bba-de36-af02-5678-15c6be1bc291-831356d6 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-e79c3cf4-71ed-92b1-74ac-6d62242a762c-831356d6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe6321-4a1356d7 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe6323-4a1356d7 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_13ca8754-be91-4ddd-b3ab-bf89c7fe633a-4a1356d7 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-d7d6a083-9288-82c9-58c7-1b8d4bc6a7e4-4a1356d7 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_82b05d05-163a-92a0-c4e1-ad0d4d8bbcbc-4a1356d7 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-ec060297-d23b-1b78-628c-5b3bf1bfba2e-141356d8 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-ec060297-d23b-1b78-628c-5b3bf1bfba38-141356d8 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-a48498a7-1488-3437-ed28-8ee0e44bd9ef-141356d8 {
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_8346d878-a1e1-5382-fa28-de87c3094546-141356d8 {
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-a48498a7-1488-3437-ed28-8ee0e44bda10-141356d8 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_8346d878-a1e1-5382-fa28-de87c3094546-781356d9 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-a48498a7-1488-3437-ed28-8ee0e44bda10-781356d9 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-e79c3cf4-71ed-92b1-74ac-6d62242a762c-a61356dd {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb2-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb8-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb9-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcbb-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcbd-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 5;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9ef1-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9efc-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9efd-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9eff-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9f01-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 5;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb55-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb60-dd1356df {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb61-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb63-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb65-dd1356df {
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 5;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68bd-601356e0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68bf-601356e0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 3;
    grid-row-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c2-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c5-601356e0 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-align: end;
    align-self: end;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c8-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68d6-601356e0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68da-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1134-601356e0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_7ebd5683-8cf6-7af1-a0fe-963d874d1137-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1df4-601356e0 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_3a0bce8a-dd8c-945c-2065-e1f9a1cb1dfe-601356e0 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_631ab592-8291-a262-a23b-b341c7643307-601356e0 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_99a6e642-4688-ea53-853a-769574a7b68f-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_6028efc3-d369-452d-8b0c-f42572cd8cd4-601356e0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6028efc3-d369-452d-8b0c-f42572cd8cdb-601356e0 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_87a38ce2-e3ea-1898-2898-37b8860e4392-601356e0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_45e45415-08b5-95d3-7ad2-ab5a83e45f74-2a1356e1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998109-2a1356e1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-d761cf34-9f52-709a-b1c3-8df667318e47-2a1356e1 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-bed6584c-5297-9392-8143-f20620083935-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_99d881c3-f0b0-b18c-84f4-5ab25f3e656d-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998110-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998112-2a1356e1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998114-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 5;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998116-2a1356e1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 2;
    grid-row-end: 6;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998118-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 2;
    grid-row-end: 7;
  }

  #w-node-_0b76985d-e46c-6d84-bf54-4a90478f97bd-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_593aa2c5-d6eb-2a65-d4ae-295e7c4628e8-2a1356e1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_29d1c35d-ad90-00d2-4f3b-0cdf35419747-2a1356e1 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_29d1c35d-ad90-00d2-4f3b-0cdf35419754-2a1356e1 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_45e45415-08b5-95d3-7ad2-ab5a83e45f74-e21356e2 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-align: end;
    align-self: end;
  }

  #w-node-d22d5f4c-c287-7707-232e-c5ce89998109-e21356e2 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-d761cf34-9f52-709a-b1c3-8df667318e47-e21356e2 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-f58ee298-bc28-4985-75b8-1d2287652687-e21356e2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-dd012874-56d6-c421-1680-7917af968069-b21356e3 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_8bcd02af-1c24-3633-9dd0-87485375d04b-b21356e3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-align: end;
    align-self: end;
  }

  #w-node-_47244e47-992b-bf1d-8274-4339c6222543-b21356e3 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_5cecbe7e-4d88-b62f-4201-c12e611356da-b21356e3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e2-b21356e3 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e4-b21356e3 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_5cecbe7e-4d88-b62f-4201-c12e611356e6-b21356e3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_4de558a6-bfcf-1ee5-a330-eff6abe01c08-b21356e3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-fd3f7910-34f1-773d-943a-7180b4321102-b51356e5 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-b51356e5 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd211-b51356e5 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd213-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd219-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd21f-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd225-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd22b-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-feade68b-a3c6-b5fb-beb8-eff1f800a14b-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_4e4a29bd-735d-d61b-d5f5-a6e98fe8658d-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_91c7ce31-eaee-ae7d-f188-ff921e6bd679-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_80f9f928-6b9c-4cdf-0a5d-9bdb180e92f2-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_1596feb1-9435-e5b1-1afd-0c8847c35446-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_34015820-2bc0-121d-8a40-474203a92e98-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-c1fa77a3-1061-6396-5950-980470d5e781-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-ba334270-b193-7969-c51c-1a59917b1923-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-a15eabde-e156-104c-79a3-fe6748e95400-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_7d1eccda-d86e-890b-3aad-89a92d9e728c-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-e3472f6e-5c6a-ca69-5eb1-aaa7ec674f75-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_3aaa5b60-d7c4-985a-71a3-5d9beca24ed2-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_23ef4db9-4747-4550-867a-5be21c9a5400-b51356e5 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_1501b47a-005f-2358-bdd1-90e3fee49cd1-ea1356e9 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c7e-ea1356e9 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c81-ea1356e9 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c83-ea1356e9 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_2d59c3cf-fa80-dfd0-370f-7ebed50b2c88-ea1356e9 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_8d28cfa8-420e-1f9a-cfa4-6a8dd9fd3980-ea1356e9 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-_8d28cfa8-420e-1f9a-cfa4-6a8dd9fd3983-ea1356e9 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_8a467164-3221-77e9-9900-d4d974a1f239-ea1356e9 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-_8a467164-3221-77e9-9900-d4d974a1f23c-ea1356e9 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_554a4b7c-050e-ef5a-75a1-664e7514106d-ea1356e9 {
    -ms-grid-column-span: 2;
    grid-column-end: 2;
  }

  #w-node-_554a4b7c-050e-ef5a-75a1-664e75141070-ea1356e9 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_325151c2-43b6-2a66-8b9a-39b43e1e24db-6e2ba270 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_325151c2-43b6-2a66-8b9a-39b43e1e24e2-6e2ba270 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd09-6e2ba270 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd15-6e2ba270 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd28-6e2ba270 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd42-6e2ba270 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-bb82b183-06ef-7ee5-df74-93cc5d0c9eba-6e2ba270 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-bb82b183-06ef-7ee5-df74-93cc5d0c9ebf-6e2ba270 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_69f4f874-a90d-ff16-22ef-f60bb2d87fb5-6e2ba270 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_69f4f874-a90d-ff16-22ef-f60bb2d87fbf-6e2ba270 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1bbe-689a1bac {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-dc56a4fd-36a8-297e-5ec9-d26cf9b6cccd-1e1356bb {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-dc56a4fd-36a8-297e-5ec9-d26cf9b6ccd0-1e1356bb {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb9-dd1356df {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcbd-dd1356df {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9efd-dd1356df {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9f01-dd1356df {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb61-dd1356df {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb65-dd1356df {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd09-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd0c-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd0e-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd15-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd18-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd28-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd2b-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd42-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_12e9193b-b28a-fbb6-7c2f-df2b869fdd45-6e2ba270 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_07696ed0-4e2a-15f0-a38e-15e91eb5d739-641355f5 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_436397a8-f8b2-c65b-9c4b-184ae30c57af-641355f5 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 9;
    grid-column-start: span 9;
    -ms-grid-column-span: 9;
    grid-column-end: span 9;
  }

  #w-node-_40264d96-923e-613a-3616-afc9ac33830f-ac33830c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-5e135657 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_7bd942c7-e5d6-953f-6756-c704689a1bbe-689a1bac {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6926-6e1356c1 {
    -ms-grid-row-span: 2;
    grid-row-end: 2;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6928-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a692d-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_8f15790a-8a94-1d8e-57df-278b5a3a6936-6e1356c1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_05ca1d5d-0078-e532-7ddb-bb69d84795c2-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_1ac350bb-fa6e-aa39-742d-49ab3f22ca67-fb1356c2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-f3bd5cf0-8913-1fa4-151f-666135534fdd-fb1356c2 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_3aa120c3-9c8f-b550-2ac0-f6480e072c4e-741356c5 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_0043e48f-2245-aefe-0552-b932e431f934-821356c8 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_21c33300-49e5-a888-2b92-b4d1b1cf3920-a31356d2 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-a82ac5a7-b0d8-cf4c-d6ec-a344c3ccdd40-a61356dd {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-abc02196-e811-bbd0-9ff5-7657018f1902-a61356dd {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb2-dd1356df {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcb8-dd1356df {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcbb-dd1356df {
    -ms-grid-column-span: 2;
    grid-column-end: 5;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_94f4f095-69d3-49e7-5ca7-55f9e841bcbd-dd1356df {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 3;
    grid-column-end: 5;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9eff-dd1356df {
    -ms-grid-column-span: 2;
    grid-column-end: 5;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6fa2a41a-c795-9a23-5e3f-8de317fe9f01-dd1356df {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb63-dd1356df {
    -ms-grid-column-span: 2;
    grid-column-end: 5;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_691f16a3-6d61-2183-e540-750cd437fb65-dd1356df {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }

  #w-node-c0e24a08-8fea-bd54-4fc1-7c57f3bc68c8-601356e0 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_87a38ce2-e3ea-1898-2898-37b8860e4392-601356e0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-a30dcc32-ee4d-b4ed-b8fc-f71583bd5093-e21356e2 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 3;
    grid-column-end: 5;
  }

  #w-node-_6c1f3deb-1311-657d-5340-b74496373e3f-e21356e2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-a95ad4b9-045a-964b-eedc-1d1041f40f62-e21356e2 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 2;
    grid-column-end: 6;
  }

  #w-node-c58c0872-8ede-8319-f5d4-6388980dd20e-b51356e5 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }
}

@font-face {
  font-family: 'Tanker';
  src: url('fonts/Tanker-Regular.woff') format('woff'), url('fonts/Tanker-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cabinetgrotesk';
  src: url('fonts/CabinetGrotesk-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cabinetgrotesk';
  src: url('fonts/CabinetGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cabinetgrotesk';
  src: url('fonts/CabinetGrotesk-Bold.otf') format('opentype'), url('fonts/CabinetGrotesk-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cabinetgrotesk';
  src: url('fonts/CabinetGrotesk-Extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}